import { Table, Col, Button, Space, Modal, Form, Input, notification } from "antd";
import React, { useEffect, useState } from "react";
import axios from "axios";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import SideBar from "./SideBar";
import moment from "moment";
import Spinner from "../CommonComponent/Spinner";
import {
  deleteUser,
  updateUser,
} from "../../redux/slices/AddUser/EnrollUserAction";

import editgig from "../../components/assets/images/editgig.gif";
import deleteicon from "../../components/assets/images/deleteicon.png";
import notfound from "../../components/assets/images/notfound.png";
import password1 from "../../components/assets/images/password.png";
import excel from "../../components/assets/images/excel.png";

const ListOfUsers = () => {
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [selectedUser, setSelectedUser] = useState(null);

  const token = JSON.parse(localStorage.getItem("slotbook"));
 
  const accessToken = token?.accessToken;

  const handleEdit = (record) => {
    setVisible(true);
    setSelectedUser(record);
    form.setFieldsValue({
      user_name: record.user_name,
      user_email: record.user_email,
      gender_name: record.gender_name,
      user_role_name: record.user_role_name,
      password: record.password,
      user_mobile: record.user_mobile,
    });
  };

  const handleDelete = (record) => {
    Modal.confirm({
      title: "Confirm Deletion",
      content: "Are you sure you want to delete this user?",
      onOk() {
        deleteUser(record.id)
          .then(() => {
            setUserList((prevUserList) =>
              prevUserList.filter((user) => user.id !== record.id)
            );
            swal({
              title: "User Deleted!",
              text: ``,
              icon: "success",
              button: "Close",
            });
          })
          .catch((error) => {
            swal({
              title: "Error!",
              text: ``,
              icon: "warning",
              button: "Close",
            });
          });
      },
    });
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        const updatedUser = { ...selectedUser, ...values };

        updateUser(updatedUser.id, updatedUser)
          .then((apiResponse) => {
           
            setUserList((prevUserList) =>
              prevUserList.map((user) =>
                user.id === updatedUser.id ? updatedUser : user
              )
            );
            setVisible(false);
          })
          .catch((error) => {
            console.log("Error updating user:", error);
          });
      })
      .catch((error) => {
        console.log("Validation failed:", error);
      });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  useEffect(() => {
    const fetchUserList = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          "https://apipostman.onlinecu.in/exam_sch/api/user_table/create", {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
          // "https://apipostman.onlinecu.in/exam_sch/api/user_table/create"
        );
        const data = await response.json();
          console.log(data, "USERS")
        const sortedData = data?.results?.sort((a, b) => b.user_id - a.user_id);
        setUserList(sortedData || []);
        const totalList = data.length;
      } catch (error) {
        console.log("Error");
      }
      setLoading(false);
    };

    fetchUserList();
  }, []);

  const columns = [
    {
      title: "S.No",
      dataIndex: "serialNumber",
      key: "serialNumber",
      render: (text, record, index) => index + 1 + totalRecords,
    },
    {
      title: "Name",
      dataIndex: "user_name",
      key: "user_name",
    },
    {
      title: "Email",
      dataIndex: "user_email",
      key: "user_email",
    },

    // {
    //   title: "Gender",
    //   dataIndex: "gender_name",
    //   key: "gender_name",
    // },
    {
      title: "Role",
      dataIndex: "user_role_name",
      key: "user_role_name",
    },
    {
      title: "Created at",
      dataIndex: "created_at",
      key: "created_at",
      render: (createdAt) => moment(createdAt).format("DD/MM/YYYY HH:mm:ss"),
    },
    {
      title: "Password",
      dataIndex: "password",
      key: "password",
      // render: (password) =>
      //   password ? (
      //     <img style={{ width: "30px", padding: "15px" }} src={password1} />
      //   ) : (
      //     ""
      //   ),
    },
    {
      title: "Mobile",
      dataIndex: "user_mobile",
      key: "user_mobile",
      render: (mobile) =>
        mobile ? (
          mobile
        ) : (
          <img
            style={{ width: "30px", marginRight: "10px", padding: "20px" }}
            src={notfound}
          />
        ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Space size="middle">
          <button
            style={{
              width: "100px",
              backgroundColor: "rgb(64 105 79)",
              marginRight: "10px",
            }}
            type="primary"
            onClick={() => handleEdit(record)}
          >
            Edit{" "}
            <span style={{ marginLeft: "10px" }}>
              <img
                style={{
                  width: "20px",
                  marginTop: "3px",
                  borderRadius: "50%",
                }}
                src={editgig}
              />
            </span>
          </button>
          <button
            style={{ width: "100px" }}
            type="primary"
            danger
            onClick={() => handleDelete(record)}
          >
            Delete{" "}
            <span style={{ marginLeft: "10px" }}>
              <img
                style={{ width: "20px", marginTop: "3px" }}
                src={deleteicon}
              />
            </span>
          </button>
        </Space>
      ),
    },
  ];

  const handleSearch = (value) => {
    setSearchText(value);
  };

  const userListSearch = userList.filter((item) => {
    const lowerSearchText = searchText.toLowerCase();
    return (
      Object.values(item).some(
        (value) =>
          typeof value === "string" &&
          value.toLowerCase().includes(lowerSearchText)
      ) ||
      (typeof item.program_level === "string" &&
        item.program_level.toLowerCase().includes(lowerSearchText))
    );
  });


  // const downloadUsers = async () => {
  //   setLoading(true);
  //   try {
  //     const response = await axios.get(
  //       "https://apipostman.onlinecu.in/exam_sch/api/user_table/create"
  //     );
  //     console.log("Server Response:", response);

  //     const userData = response.data.results;
  //     console.log("User Data:", userData);

  //     if (!Array.isArray(userData) || userData.length === 0) {
  //       swal({
  //         title: "Invalid user data format!",
  //         text: ``,
  //         icon: "warning",
  //         button: "Close",
  //       });
  //       return;
  //     }

  //     const fileType =
  //       "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  //     const fileExtension = ".xlsx";
  //     const ws = XLSX.utils.json_to_sheet(userData);
  //     const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  //     const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  //     const BigData = new Blob([excelBuffer], { type: fileType });
  //     FileSaver.saveAs(BigData, "User-Table" + fileExtension);
  //     swal({
  //       title: "Download Successful!",
  //       text: ``,
  //       icon: "success",
  //       button: "Close",
  //     });
  //     setLoading(false);
  //   } catch (error) {
  //     console.error("Download Error:", error);
  //     setLoading(false);
  //     swal({
  //       title: "Error!",
  //       text: ``,
  //       icon: "warning",
  //       button: "Close",
  //     });
  //   }
  // };



  const downloadUsers = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        // "https://apipostman.onlinecu.in/exam_sch/api/user_table/create"
        "https://apipostman.onlinecu.in/exam_sch/api/user_table/create", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log("Server Response:", response);
  
      const userData = response.data.results;
      console.log("User Data:", userData);
  
      if (!Array.isArray(userData) || userData.length === 0) {
        swal({
          title: "Invalid user data format!",
          text: ``,
          icon: "warning",
          button: "Close",
        });
        return;
      }
  
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
  
 
      const ws = XLSX.utils.json_to_sheet(userData);
  

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Users");
  

      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  

      const excelBlob = new Blob([excelBuffer], { type: fileType });
  

      FileSaver.saveAs(excelBlob, "User-Table" + fileExtension);
  
      notification.success({
        message: "Download Success",
        description: "Excel File for User Table Downloaded"
      });
      setLoading(false);
    } catch (error) {
      console.error("Download Error:", error);
      setLoading(false);
      notification.error({
        message: "Download Failed",
        description: "Excel File for User Table Download Failed"
      });
    }
  };
  
  






  return (
    <>
      <SideBar />
      {loading ? (
        <Spinner />
      ) : (
        <div className="contain-table">
          <div style={{ marginTop: "130px" }} className="table-list">
            <h2>User List</h2>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <Input.Search
                  placeholder="Search by Mail, Name, UID"
                  onSearch={handleSearch}
                  style={{ marginBottom: 16, width: "300px" }}
                />
              </div>
              <div>
                <button  onClick={downloadUsers}>
                  Download Users <img style={{ width:"30px", marginLeft:"10px"}} src={excel} />{" "}
                </button>
              </div>
            </div>

            <Col>
              <Table
                dataSource={userListSearch}
                columns={columns}
                pagination={{
                  pageSize: 5,
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`,
                }}
              />

              <Modal
                title="Edit User"
                visible={visible}
                onOk={handleOk}
                onCancel={handleCancel}
              >
                <Form form={form} layout="vertical">
                  {/* <Form.Item name="serialNumber" label="S.No">
                      <Input />
                    </Form.Item> */}
                  <Form.Item name="user_name" label="Name">
                    <Input />
                  </Form.Item>
                  <Form.Item name="user_email" label="Email">
                    <Input />
                  </Form.Item>
                  <Form.Item name="gender_name" label="Gender">
                    <Input />
                  </Form.Item>

                  <Form.Item name="user_role_name" label="Role">
                    <Input />
                  </Form.Item>
                  <Form.Item name="password" label="Password">
                    <Input />
                  </Form.Item>
                  <Form.Item name="user_mobile" label="Mobile">
                    <Input />
                  </Form.Item>
                </Form>
              </Modal>
            </Col>
          </div>
        </div>
      )}
    </>
  );
};

export default ListOfUsers;
