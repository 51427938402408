import React, { useEffect } from "react";
import "./EmailCard.css";
import { Link } from "react-router-dom";
import SideBar from "../AdminComponent/SideBar";
import { notification } from "antd";

const EmailCard = () => {
  const getUser = JSON.parse(localStorage.getItem("adminData"));

  useEffect(() => {
    // Retrieve and display the login success notification from sessionStorage
    const loginNotification = sessionStorage.getItem("loginNotification");

    if (loginNotification) {
      const { message, description } = JSON.parse(loginNotification);

      notification.success({
        message,
        description,
      });

      // Clear the notification from sessionStorage to avoid displaying it again
      sessionStorage.removeItem("loginNotification");
    }
  }, []);

  return (
    <>
      {getUser?.user_role_id === 2 ? null : <SideBar />}

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          width: "100vw",
          gap: "30px",
          flexWrap: "wrap",
        }}
      >
        <div
          style={{
            marginTop: "140px",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            width: "100vw",
            gap: "30px",
            flexWrap: "wrap",
          }}
        >
          <Link to="/email-temp" style={{ textDecoration: "none" }}>
            <div className="card-wrap">
              <div className="card-header one">
                <i className="fas fa-code"></i>
              </div>
              <div className="card-content">
                <h1 style={{ width: "200px" }} className="card-title">
                  Email Template Service{" "}
                </h1>
                <p className="card-text">Email someone using a template.</p>

                <button className="card-btn one">Go</button>
              </div>
            </div>
          </Link>
          <Link to="/Anonymous" style={{ textDecoration: "none" }}>
            <div className="card-wrap">
              <div className="card-header three">
                <i className="fab fa-html5"></i>
              </div>
              <div className="card-content">
                <h1 className="card-title">Quick Mail Service</h1>
                <p className="card-text">
                  Announcements, pending fees, or mass notifications.
                </p>

                <button  className="card-btn three">Go</button>
              </div>
            </div>
          </Link>

          <Link to="/email-record" style={{ textDecoration: "none" }}>
            <div className="card-wrap">
              <div className="card-header two">
                <i className="fab fa-js-square"></i>
              </div>
              <div className="card-content">
                <h1 className="card-title">Get Email Reports</h1>
                <p className="card-text">
                  Success and failed email deliveries.
                </p>

                <button className="card-btn two">Go</button>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
};

export default EmailCard;
