import React, { useEffect, useState } from "react";
import whitelogo from "../assets/images/brandlogo3.png";
import background from "../assets/images/background-brand.jpg";
import { Avatar, Button, Dropdown, Space } from "antd";
import { Link } from "react-router-dom";
import {
  HomeOutlined,
  LogoutOutlined,
  QuestionCircleOutlined,
  UnorderedListOutlined,
  UserOutlined,
} from "@ant-design/icons";
import CircleNameDisplay from "../CircleNameDisplay ";
// import chandiLogo from "../assets/images/chandiLogo.png";
import chandiLogo from "../assets/images/chandiLogo.png";

const Header = () => {
  const [adminData, setAdminData] = useState(null);

  const clearLocal = () => {
    window.location.reload();
    localStorage.clear();
    window.location.href = "/login";
  };

  useEffect(() => {
    const storedAdminData = localStorage.getItem("adminData");

    if (storedAdminData) {
      const parseAdminData = JSON.parse(storedAdminData);
      setAdminData(parseAdminData);
    }
  }, []);

  const showHomeButton = location.pathname !== "/login";

  const items = [
    // {
    //   key: "1",
    //   label: (
    //     <Link className="link-name" to="/help">
    //       {" "}
    //       <span>Need Help ? </span>{" "}
    //       <span style={{ marginRight: "10px" }}></span>
    //       <QuestionCircleOutlined />
    //     </Link>
    //   ),
    // },
    {
      key: "2",
      label: (
        <Link className="link-name" to="/profile">
          {" "}
          <span>Profile </span>{" "}
          <span style={{ marginRight: "10px" }}></span>
          <UserOutlined />
        </Link>
      ),
    },
    {
      key: "3",
      label: (
        <Link onClick={clearLocal} className="link-name" to="/login">
          {" "}
          <span>Logout</span>
          <span style={{ marginRight: "10px" }}></span>
          <LogoutOutlined />
        </Link>
      ),
    },
  ];

  return (
    <>
      <div className="header-top">
        <div className="new-navbar">
          <div>
            {" "}
            <Link to="/email">
            <img
              style={{ width: "200px", marginTop: "10px" }}
              src={chandiLogo}
            />
            </Link>
           
          </div>

          <div>
            <h3 className="heading-navbar"><span style={{color:"#CE1A19"}}>CU</span>-Postman</h3>
          </div>

          <div  className="login">
            <Space direction="vertical">
              <Space wrap>
                {showHomeButton && (
                  <Dropdown
                    menu={{
                      items,
                    }}
                    placement="bottomLeft"
                  >
                    <Button className="login-btn">
                      {adminData?.user_name}
                      <Space style={{marginRight:"10px"}} direction="vertical" size={16}>
                        <Space wrap size={14}>
                          <Avatar
                            size={50}
                            icon={
                              <CircleNameDisplay name={adminData?.user_name} />
                            }
                          />
                        </Space>
                      </Space>
                    </Button>
                  </Dropdown>
                )}
              </Space>
            </Space>
          </div>
        </div>
       
      </div>
    </>
  );
};

export default Header;
