import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Header from "./src/components/CommonComponent/Header";
import { Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";
import ErrorComponent from "./src/components/CommonComponent/ErrorComponent";
import LoginPage from "./src/components/CommonComponent/Login/LoginPage";
import HomePage from "./src/components/HomePage";
import { Provider } from "react-redux";
import { store } from "./src/redux/store";
import AddUser from "./src/components/AdminComponent/AddUser";
import StudentHomePage from "./src/components/StudentComponent/StudentHomePage/StudentHomePage";
import SlotBookForm from "./src/components/StudentComponent/StudentHomePage/SlotBookForm";
import ListOfUsers from "./src/components/AdminComponent/ListOfUsers";
import EnrollStudent from "./src/components/AdminComponent/EnrollStudent";
import StudentForm from "./src/components/AdminComponent/DummyEnroll";
import useOnlineStatus from "./src/components/CommonComponent/useOnlineOffline";
import EnrolledStudentList from "./src/components/AdminComponent/EnrolledStudentList";
import MenuItems from "./src/components/AdminComponent/MenuItems";
import AvailSlot from "./src/components/AdminComponent/AvailSlot";
import BulkuploadSlots from "./src/components/AdminComponent/BulkuploadSlots";
import BulkUserUpload from "./src/components/AdminComponent/BulkUserUpload";
import AdminProfile from "./src/components/AdminComponent/AdminProfile";
import SendMailAno from "./src/components/AdminComponent/SendMailAno";
import TemplateEmail from "./src/components/EmailService/TemplateEmail";
import EmailCard from "./src/components/EmailService/EmailCard";
import EmailRecord from "./src/components/EmailService/EmailRecord";
import ErrorComponent from "./src/components/CommonComponent/ErrorComponent";
import AppPrivateRoute from "./AppPrivateRoute";
import EditGroup from "./src/components/AdminComponent/EditGroup";
import CreateGroup from "./src/components/AdminComponent/CreateGroup";


const AppComponent = () => {
  const handleContextMenu = (e) => {
    e.preventDefault();
    swal({
      title: "Right-clicking is disabled in this app. ",
      text: "You are not allowed to open developer tools.",
      icon: "error",
      button: "Close",
    });
  };
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [lastActivityTime, setLastActivityTime] = useState(Date.now());

  useEffect(() => {
    const activityEvents = ["mousemove", "mousedown", "keypress", "scroll"];

    const resetTimer = () => {
      setLastActivityTime(Date.now());
    };

    activityEvents.forEach((event) => {
      window.addEventListener(event, resetTimer);
    });

    const checkIdleTime = () => {
      const currentTime = Date.now();
      const idleTime = currentTime - lastActivityTime;
      if (idleTime > 300000 && isLoggedIn) {
        swal("Session time expire")
        localStorage.clear();
        setIsLoggedIn(false);
      }
    };

    const idleTimer = setInterval(checkIdleTime, 50000);

    return () => {
      clearInterval(idleTimer);
      activityEvents.forEach((event) => {
        window.removeEventListener(event, resetTimer);
      });
    };
  }, [lastActivityTime, isLoggedIn]);

  const onlineStatus = useOnlineStatus();
  if (!onlineStatus) {
    swal({
      title: "OOPs no internet !",
      text: "I think you should contact your internet provider",
      icon: "error",
      buttons: false,
    });
  }

  const isLoginPage = location.pathname === "/login";

  useEffect(() => {
    const getUser = JSON.parse(localStorage.getItem("adminData"));
    if (getUser?.user_role_id === 2) {
      children = null;
    }
  }, []);

  return (
    <>
      <div onContextMenu={handleContextMenu}>
      
        {!isLoginPage && <Header />}
        {isLoggedIn ? <Outlet /> : <LoginPage />}
      </div>
    </>
  );
};

const appRoute = createBrowserRouter([
  {
    path: "/",
    element: <AppComponent />,
    errorElement: <ErrorComponent />,
    children: [
      {
        path: "/",
        element: (
          <AppPrivateRoute>
            <HomePage />
          </AppPrivateRoute>
        ),
      },
      {
        path: "/add-user",
        element: (
          <AppPrivateRoute>
            <AddUser />
          </AppPrivateRoute>
        ),
      },

      {
        path: "/slot-book",
        element: (
          <AppPrivateRoute>
            <StudentHomePage />
          </AppPrivateRoute>
        ),
      },
      {
        path: "/slot-book-form",
        element: (
          <AppPrivateRoute>
            <SlotBookForm />
          </AppPrivateRoute>
        ),
      },
      {
        path: "/user_table",
        element: (
          <AppPrivateRoute>
            <ListOfUsers />
          </AppPrivateRoute>
        ),
      },
      {
        path: "/enroll_student",
        element: <EnrollStudent />,
      },
      {
        path: "/dummy_enroll",
        element: (
          <AppPrivateRoute>
            <StudentForm />
          </AppPrivateRoute>
        ),
      },
      {
        path: "/profile",
        element: <AdminProfile />,
      },
      {
        path: "/enrolled_students",
        element: (
          <AppPrivateRoute>
            <EnrolledStudentList />
          </AppPrivateRoute>
        ),
      },
      {
        path: "/menu",
        element: (
          <AppPrivateRoute>
            <MenuItems />
          </AppPrivateRoute>
        ),
      },
      {
        path: "/create-slot",
        element: (
          <AppPrivateRoute>
            <AvailSlot />
          </AppPrivateRoute>
        ),
      },
      {
        path: "/email",
        element: <EmailCard />,
      },
      {
        path: "/email-temp",
        element: <TemplateEmail />,
      },
      {
        path: "/bulkUpload",
        element: (
          <AppPrivateRoute>
            <BulkuploadSlots />
          </AppPrivateRoute>
        ),
      },
      {
        path: "/bulk-user-upload",
        element: (
          <AppPrivateRoute>
            <BulkUserUpload />
          </AppPrivateRoute>
        ),
      },
      {
        path: "/email-record",
        element: <EmailRecord />,
      },
      {
        path: "/Anonymous",
        element: <SendMailAno />,
      },
      {
        path: "/edit/:id",
        element: <EditGroup />,
      },
      {
        path: "/create-group",
        element: <CreateGroup />,
      },

      {
        path: "/login",
        element: <LoginPage />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(<AppComponent />);
root.render(
  <Provider store={store}>
    <RouterProvider router={appRoute} />
  </Provider>
);
