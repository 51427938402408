import React, { useState } from "react";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import SideBar from "./SideBar";

const BulkuploadSlots = () => {
  const [uploadedFile, setUploadedFile] = useState(null);

  const token = JSON.parse(localStorage.getItem("slotbook"));

  const accessToken = token?.accessToken;
  

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    setUploadedFile(file);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleUpload = async () => {
    try {
      const formData = new FormData();
      formData.append("file", uploadedFile);

      const response = await axios.post(
        // "https://apipostman.onlinecu.in/exam_sch/api/exam_sch/bulk_upload/",
        "https://apipostman.onlinecu.in/exam_sch/api/exam_sch/bulk_upload/",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );


      // Handle success (e.g., show a success message)
    } catch (error) {
      console.error("Upload failed:", error);
      // Handle error (e.g., show an error message)
    }
  };

  return (
    <>
      <SideBar />
      <div className="bulkupload">
        <div
          className="bulkupload-page"
          {...getRootProps()}
          style={dropzoneStyles}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Drop the file here...</p>
          ) : (
            <p>Drag & drop an Excel file here, or click to select one</p>
          )}
        </div>

        {uploadedFile && (
          <div className="fileUpload">
            <p>File uploaded: {uploadedFile.name}</p>

            <button onClick={handleUpload}>Upload</button>
          </div>
        )}
      </div>
    </>
  );
};

const dropzoneStyles = {
  border: "2px dashed #cccccc",
  borderRadius: "4px",
  padding: "20px",
  textAlign: "center",
  cursor: "pointer",
  marginTop: "20px",
};

export default BulkuploadSlots;
