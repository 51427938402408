import React, { useEffect, useRef, useState } from "react";
import { ConfigProvider } from "antd";
import Select from "react-select";
import "intl-tel-input/build/css/intlTelInput.css";
import intlTelInput from "intl-tel-input";

import SideBar from "./SideBar";
import { fetchProgram } from "../../redux/slices/AddUser/EnrollUserAction";
import { PostEnrollStudent } from "../../redux/slices/AddUser/postEnrollUserAction";

import Loading from "../CommonComponent/Loading";
import OpenModalForBulkStudents from "./OpenModalForBulkStudents";
import ExcelDownloadButton from "./DownloadXlsx";
import ExcelDownloadButtonStudent from "./DownloadExcelButtonStudent";

const StudentForm = () => {
  const mobileCodeInputRef = useRef(null);

  const [loading, setLoading] = useState(false);

  const [studentName, setStudentName] = useState("");
  const [studentUid, setStudentUid] = useState("");
  const [studentEmail, setStudentEmail] = useState("");
  const [session, setSession] = useState([]);
  const [sessionSelected, setSessionSelected] = useState("");
  const [programLevel, setProgramLevel] = useState("");
  const [selectedProgramLevel, setSelectedProgramLevel] = useState("");
  const [courses, setCourses] = useState([]);
  const [selectedCourses, setSelectedCourse] = useState("");

  const [gender, setGender] = useState([]);
  const [genderSelected, setGenderSelected] = useState("");

  const [semester, setSemester] = useState([]);
  const [selectedSemester, setSelectedSemester] = useState("");

  const [programCourses, setProgramCourses] = useState([]);

  const [phoneNumber, setPhoneNumber] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [country, setCountry] = useState("");
  const [countryCode, setCountryCode] = useState("");

  const [selectedSpecializations, setSelectedSpecializations] = useState([]);
  const [specializationOptions, setSpecializationOptions] = useState([]);
  const [selectedElectives, setSelectedElectives] = useState([]);
  const [electivesOptions, setElectivesOptions] = useState([]);

  const [selectedCategoryId, setSelectedCategoryId] = useState([]);



  const token = JSON.parse(localStorage.getItem("slotbook"));
  
  const accessToken = token?.accessToken;


  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    let specIdsArray = selectedSpecializations.map((spec) => spec.value).flat();
    let elecIdArray = selectedElectives.map((elec) => elec.value).flat();

    try {
      await PostEnrollStudent(
        studentName,
        studentUid,
        studentEmail,
        phoneNumber,
        sessionSelected,
        selectedProgramLevel,
        genderSelected,
        selectedCourses,
        selectedSemester,
        specIdsArray,
        elecIdArray
      );
    } finally {
      setLoading(false);
    }

   
  };

  useEffect(() => {
    const mobileCodeInput = mobileCodeInputRef.current;
    if (mobileCodeInput) {
      intlTelInput(mobileCodeInput, {
        initialCountry: "in",
        separateDialCode: true,
      });
    }
  }, []);

  useEffect(() => {
    fetch(`https://apipostman.onlinecu.in/exam_sch/api/sessions_list/`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => response?.json())
      .then((data) => {
        setSession(data);
       
      })

      .catch((error) => {
        console.error("Error fetching data user role: ", error);
      });
  }, []);



  // useEffect(() => {
  //   // fetch(`https://apipostman.onlinecu.in/exam_sch/api/genders_create/`)
  //   fetch(`https://apipostman.onlinecu.in/exam_sch/api/genders_create/`), {
  //     headers: {
  //       Authorization: `Bearer ${accessToken}`,
  //     },
  //   }
  //     .then((response) => response?.json())
  //     .then((data) => {
  //       setGender(data);
  //       console.log( data, "GENDER")
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching data: ", error);
  //     });
  // }, []);

  useEffect(() => {
    fetch(`https://apipostman.onlinecu.in/exam_sch/api/genders_create/`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => response?.json())
      .then((data) => {
        setGender(data);
      
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, []);
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiResponse = await fetchProgram();
       
        const updatedData = apiResponse?.data;
        setProgramLevel(updatedData);
       

        if (apiResponse?.status === 200) {
          setProgramLevel(updatedData);
        }
      } catch (error) {
        console.log(error, "PPP");
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    // fetch(`https://apipostman.onlinecu.in/exam_sch/api/semester_list/`)
    fetch(`https://apipostman.onlinecu.in/exam_sch/api/semester_list/`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setSemester(data);
      
      })
      .catch((error) => {
        console.log(error, "Error");
      });
  }, []);

  useEffect(() => {
    const fetchCourses = async () => {
      if (selectedProgramLevel !== "") {
        try {
          const response = await fetch(
            // `https://apipostman.onlinecu.in/exam_sch/api/program-search/?prog_level_id=${selectedProgramLevel}`
            `https://apipostman.onlinecu.in/exam_sch/api/program-search/?prog_level_id=${selectedProgramLevel}`, {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );
          const data = await response.json();
        

          setProgramCourses(data);
          setCourses(data);
        } catch (error) {
          console.error("Error fetching courses: ", error);
        }
      }
    };

    fetchCourses();
  }, [selectedProgramLevel]);

  useEffect(() => {
    const mobileCodeInput = mobileCodeInputRef.current;
    if (mobileCodeInput) {
      // Initialize intl-tel-input library with separateDialCode set to true
      intlTelInput(mobileCodeInput, {
        initialCountry: "in",
        separateDialCode: true,
      });
    }
  }, []);

  //  specialization search
  const fetchSpecilazation = async () => {
    if (selectedCourses !== "" && selectedSemester !== "") {
      try {
        const response = await fetch(
          // `https://apipostman.onlinecu.in/exam_sch/api/specialization-search/?program_id=${selectedCourses}&semester_id=${selectedSemester}`
          `https://apipostman.onlinecu.in/exam_sch/api/specialization-search/?program_id=${selectedCourses}&semester_id=${selectedSemester}`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        const data = await response.json();
       

        if (Array.isArray(data.specialization)) {
       

          const combinedSpecIds = data.specialization
            .flatMap(
              (spec) =>
                spec.specializationList?.map((item) => item.spec_id) || []
            )
            .filter(Boolean);

        

          // Now you can use combinedSpecIds as needed in your application.

          const specializationOptions = data.specialization.map((spec) => ({
            label: spec.category,
            value: spec.specializationList?.map((item) => item.spec_id),
          }));

          setSpecializationOptions(specializationOptions);
        } else {
          console.error("Invalid data format for specializations");
        }
      } catch (error) {
        console.error("Error fetching specializations: ", error);
      }
    }
  };

  // elective search

  const fetchElective = async () => {
    if (selectedCourses !== "" && selectedSemester !== "") {
      try {
        const response = await fetch(
          // `https://apipostman.onlinecu.in/exam_sch/api/electives-search/?program_id=${selectedCourses}&semester_id=${selectedSemester}`
          `https://apipostman.onlinecu.in/exam_sch/api/electives-search/?program_id=${selectedCourses}&semester_id=${selectedSemester}`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        const apiResponse = await response.json();
      

        if (Array.isArray(apiResponse.electives)) {
        

          const electivesOptions = apiResponse?.electives?.map((elec) => ({
            label: elec.category,
            options:
              elec.electiveList?.map((item) => ({
                label: item.elective_name,
                // value: `${elec.category}-${item.elective_name}`,
                value: item.elective_id,
                color: "red",
              })) || [],
          }));

          setElectivesOptions(electivesOptions);
        } else {
          console.error("Invalid data format for Electives");
        }
      } catch (error) {
        console.error("Error fetching Electives: ", error);
      }
    }
  };

  useEffect(() => {
    fetchSpecilazation();
  }, [selectedCourses, selectedSemester]);

  useEffect(() => {
    fetchElective();
  }, [selectedCourses, selectedSemester]);

  const handleSpecializationsChange = (selectedOptions) => {
    if (selectedOptions.length > 2) {
      swal({
        title: "Warning",
        text: "You can select only two Specializations",
        icon: "warning",
        button: "Close",
      });
      return;
    }

    setSelectedSpecializations(selectedOptions);

    const selectedSpecIds = selectedOptions.flatMap((option) => option.value);
    setSelectedCategoryId(selectedSpecIds);
  };

  const handleElectivesChange = (selectedEleOptions) => {
    if (selectedEleOptions.length > 2) {
      swal({
        title: "Warning",
        text: "You can select only two Electives",
        icon: "warning",
        button: "Close",
      });
      return;
    }
    if (selectedEleOptions.length) {
      // if elec 2 one from each.
      // if elec one
      //
      // swal({
      //   title: "Warning",
      //   text: "You can select only two Electives",
      //   icon: "warning",
      //   button: "Close",
      // });
      // return;
    }
    setSelectedElectives(selectedEleOptions);

    const selectedSpecIds = selectedEleOptions.flatMap(
      (option) => option.value
    );
    setSelectedCategoryId(selectedSpecIds);
  };

  const handleCountryCodeChange = (e) => {
    setCountryCode(e.target.value);
  };

  // const handlePhoneNumberChange = (e) => {
  //   let inputValue = e.target.value;

  //   inputValue = inputValue.replace(/[^0-9+]/g, "");

  //   const isValidInput = /^\+?[0-9]{10,13}$/.test(inputValue);

  //   setPhoneNumber(inputValue);
  //   setIsValid(isValidInput);
  // };

  const handlePhoneNumberChange = (e) => {
    let inputValue = e.target.value;

    // Use intl-tel-input's getSelectedCountryData method to get the selected country code
    const countryData = mobileCodeInputRef?.current?.intlTelInput?.getSelectedCountryData();
    const countryCode = countryData?.dialCode || "";

    inputValue = inputValue.replace(/[^0-9+]/g, "");

    const isValidInput = /^\+?[0-9]{10,13}$/.test(inputValue);

    // Concatenate country code with the phone number
    const phoneNumberWithCountryCode = `${countryCode}${inputValue}`;

    setPhoneNumber(phoneNumberWithCountryCode);
    setIsValid(isValidInput);
  };



  return (
    <>
      <SideBar />
      {loading ? (
        <Loading />
      ) : (
        <>
          <ConfigProvider>
            <div className="login-page">
              <div className="login-container">
                <div className="login-page-left">
                  <form onSubmit={handleSubmit}>
                    <div className="wrapper-adduser-in">
                      <div className="heading">
                        <div>
                          <h2>Enroll Students</h2>
                        </div>
                        <div></div>
                      </div>

                      <div className="flex-container">
                        <div className="input-grp">
                          <label htmlFor="studentName">Student Name:</label>
                          <input
                            placeholder="John Doe"
                            className="input-field"
                            type="text"
                            id="studentName"
                            value={studentName}
                            onChange={(e) => setStudentName(e.target.value)}
                            required
                          />
                        </div>

                        <div className="user-ipt">
                          <label htmlFor="studentEmail">Student Email:</label>
                          <input
                            placeholder="name@example.com"
                            className="input-field"
                            type="email"
                            id="studentEmail"
                            value={studentEmail}
                            onChange={(e) => setStudentEmail(e.target.value)}
                           required
                          />
                        </div>
                      </div>

                      <div className="flex-container">
                        <section className="pt-5 pb-5">
                          <div className="container text-center">
                            <div className="row">
                              <div className="col-md-12">
                                <h4>
                                  <a
                                    href="https://intl-tel-input.com/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  ></a>
                                </h4>
                              </div>
                            </div>
                          </div>

                          {/* <div className="container">
                            <div className="row justify-content-center">
                              <div className="col-md-6 mt-5 mb-5">
                                <div className="form-area">
                                  <div className="user-ipt">
                                    <label htmlFor="Country Code">
                                      Phone Number:
                                    </label>
                                    <input
                                      type="text"
                                      className="input-field"
                                      placeholder="Phone Number"
                                      name="name"
                                      ref={mobileCodeInputRef}
                                      value={phoneNumber}
                                      onChange={handlePhoneNumberChange}
                                      required
                                    />
                                  </div>
                                  {!isValid && (
                                    <p
                                      style={{ color: "red" }}
                                      className="error-message"
                                    >
                                      Please enter a valid number (10 to 13
                                      digits).
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div> */}
                        </section>

                        <div className="user-ipt">
                          <label htmlFor="phoneNumber">Mobile Number:</label>
                          <input
                            placeholder="123-4567-8901"
                            className={`input-field ${
                              isValid ? "" : "invalid"
                            }`}
                            type="text"
                            id="phoneNumber"
                            value={phoneNumber}
                            onChange={handlePhoneNumberChange}
                            required
                          />
                         
                        </div>
                      </div>

                      <div className="flex-container">
                        <div className="input-grp">
                          <label htmlFor="studentUid">Student UID:</label>
                          <input
                            placeholder="e.g. e12345"
                            className="input-field"
                            type="text"
                            id="studentUid"
                            value={studentUid}
                            onChange={(e) => setStudentUid(e.target.value)}
                            required
                          />
                        </div>

                        <div className="user-ipt">
                          <label htmlFor="session">Session:</label>
                          <select
                            className="input-field"
                            id="session"
                            value={sessionSelected}
                            onChange={(e) => setSessionSelected(e.target.value)}
                            required
                          >
                            <option key="defaulr" value={0}>
                              {" "}
                              Select Session{" "}
                            </option>
                            {session.map((sessionOption) => (
                              <option
                                key={sessionOption.session_code}
                                value={sessionOption.session_code}
                              >
                                {`${sessionOption.start_month}/${sessionOption.start_year}`}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="flex-container">
                        <div className="input-grp">
                          <label htmlFor="programLevel">Program Level:</label>

                          <select
                            className="input-field"
                            required
                            id="programLevel"
                            value={selectedProgramLevel}
                            onChange={(e) =>
                              setSelectedProgramLevel(e.target.value)
                            }
                          >
                            <option key="defaulr" value={0}>
                              {" "}
                              Select a program{" "}
                            </option>
                            {Array.isArray(programLevel) ? (
                              programLevel.map((option) => {
                                return (
                                  <>
                                    <option
                                      key={option.prog_level_id}
                                      value={option.prog_level_id}
                                    >
                                      {option.prog_level_name}
                                    </option>
                                  </>
                                );
                              })
                            ) : (
                              <option key="default" value="default">
                                No roles available
                              </option>
                            )}
                          </select>
                        </div>

                        <div className="user-ipt">
                          <label htmlFor="courses">Courses:</label>
                          <select
                            className="input-field"
                            required
                            id="courses"
                            value={selectedCourses}
                            onChange={(e) => setSelectedCourse(e.target.value)}
                          >
                            <option key="defaulr" value="default">
                              {" "}
                              Select Courses
                            </option>
                            {Array.isArray(programCourses) ? (
                              programCourses.map((option) => (
                                <option
                                  key={option.program_id}
                                  value={option.program_id}
                                >
                                  {option.program_name}
                                </option>
                              ))
                            ) : (
                              <option>No Course</option>
                            )}
                          </select>
                        </div>
                      </div>

                      <div className="flex-container">
                        <div className="input-grp">
                          <label htmlFor="semester">Semester:</label>
                          <select
                            className="input-field"
                            required
                            id="semester"
                            value={selectedSemester}
                            onChange={(e) =>
                              setSelectedSemester(e.target.value)
                            }
                          >
                            <option key="defaulr" value={0}>
                              {" "}
                              Select Semester{" "}
                            </option>
                            {Array.isArray(semester) ? (
                              semester.map((option) => {
                                return (
                                  <>
                                    <option
                                      key={option?.semester_id}
                                      value={option?.semester_id}
                                    >
                                      {option?.semester_name}
                                    </option>
                                  </>
                                );
                              })
                            ) : (
                              <option>No Data</option>
                            )}
                          </select>
                        </div>

                        <div className="user-ipt">
                          <label htmlFor="gender">Gender</label>
                          <select
                            className="input-field"
                            required
                            placeholder="Select a gender"
                            value={genderSelected}
                            onChange={(e) => setGenderSelected(e.target.value)}
                          >
                            <option key="defaulr" value="">
                              {" "}
                              Select Gender{" "}
                            </option>
                            {Array.isArray(gender) ? (
                              gender.map((option) => (
                                <option
                                  key={option.gender_id}
                                  value={option.gender_id}
                                >
                                  {option.gender_name}
                                </option>
                              ))
                            ) : (
                              <option key="default" value="default">
                                No gender options available
                              </option>
                            )}
                          </select>
                        </div>
                      </div>

                      <div>
                        <label htmlFor="specializations">
                          Specializations:
                        </label>
                        {specializationOptions.length > 0 && (
                          <Select
                            className="input-field"
                            id="specializations"
                            options={specializationOptions}
                            isMulti
                            value={selectedSpecializations}
                            onChange={(selectedOptions) =>
                              handleSpecializationsChange(selectedOptions)
                            }
                            getOptionValue={(option) => option.value}
                          />
                        )}
                        {specializationOptions.length === 0 && (
                          <p
                            style={{
                              color: "#6678c4",
                              padding: "0px",
                              marginTop: "10px",
                              fontWeight: "bold",
                            }}
                          >
                            {" "}
                            No Specializations available for the selected
                            courses and semester.
                          </p>
                        )}
                      </div>

                      <div>
                        <label htmlFor="specializations">Electives:</label>
                        {electivesOptions.length > 0 && (
                          <Select
                            className="input-field"
                            placeholder="select"
                            id="electives"
                            options={electivesOptions}
                            isMulti
                            value={selectedElectives}
                            onChange={(selectedEleOptions) =>
                              handleElectivesChange(selectedEleOptions)
                            }
                            getOptionValue={(option) => option.value}
                          />
                        )}
                        {electivesOptions.length === 0 && (
                          <p
                            style={{
                              color: "#6678c4",
                              padding: "0px",
                              marginTop: "10px",
                              marginBottom: "10px",
                              fontWeight: "bold",
                            }}
                          >
                            No Electives available for the selected courses and
                            semester.
                          </p>
                        )}
                      </div>

                      <button type="submit">Enroll</button>
                    </div>
                  </form>
                </div>
                <div style={{height:"70vh"}} className="add-user-right">
                  <div className="add-user-heading">
                    <h3 style={{ color:"white", fontWeight:"bold"}}> Bulk Student Upload</h3>
                  </div>
                  
                  <div className="add-user-body">
                    <div>
                      <OpenModalForBulkStudents />
                    </div>
                  </div>
                  <div>
                    <ExcelDownloadButtonStudent />
                  </div>
                </div>
              </div>
            </div>
          </ConfigProvider>
        </>
      )}
    </>
  );
};

export default StudentForm;
