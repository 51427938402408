// ExcelDownloadButton.js
import React from "react";
import * as XLSX from "xlsx";

const ExcelDownloadButtonStudent = () => {
  const downloadExcel = () => {
    // Sample data
    const data = [
      [
        "session",
        "program_level",
        "program",
        "semester",
        "student_uid",
        "student_email",
        "student_name",
        "student_mobile",
        "gender",
        "spec",
        "elec",
      ],
      [
        "202307",
        "PG",
        "MBA",
        "3",
        "S15299",
        "saurabhsigh36484@gmail.com",
        "saurabh kumar singh",
        "+917291089688",
        "Male",
        "22ONMBT7654,22OJHYTF5677",
        ""
      ]
     
    ];

    const ws = XLSX.utils.aoa_to_sheet(data);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "sample.xlsx";
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  return (
    <button
      style={{ background: "white", color: "black", fontWeight: "bold" }}
      onClick={downloadExcel}
    >
      Download Excel Sample
    </button>
  );
};

export default ExcelDownloadButtonStudent;
