import React, { useEffect, useState } from "react";
import "./SendMailAno.css";
import swal from "sweetalert";
import SideBar from "./SideBar";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Input, Select, Drawer, message } from "antd";
import { sendDynamicEmailsSwift } from "../../redux/slices/smtpStudents/SmtpSwiftMail";
import Holdon from "../CommonComponent/Holdon";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Loading from "../CommonComponent/Loading";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import "./CreateGroup.css";
import config from "../../Config/applicationEnv";

const CreateGroup = () => {
  const [receiverEmails, setReceiverEmails] = useState("");
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [loading, setLoading] = useState(false);
  const [totalMails, setTotalMails] = useState(0);
  const [successCount, setSuccessCount] = useState(0);
  const [failedCount, setFailedCount] = useState(0);
  const [duplicateEmails, setDuplicateEmails] = useState([]);
  const [showCorrectEmails, setShowCorrectEmails] = useState(false);

  // group states
  const [groupLoad, setGroupLoad] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [groupEmail, setGroupEmail] = useState("");
  const [bulkEmails, setBulkEmails] = useState("");
  const [groups, setGroups] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [groupCount, setGroupCount] = useState([]);

  const [deleteLoad, setDeleteLoad] = useState(false);

  const [mapDetail, setMapDetail] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);

  // group making validation
  const [duplicateEmailsGrp, setDuplicateEmailsGrp] = useState([]);
  const [wrongEmails, setWrongEmails] = useState([]);

  //drawer state
  const [open, setOpen] = useState(false);

  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  const token = JSON.parse(localStorage.getItem("slotbook"));

  const accessToken = token?.accessToken;

  const navigate = useNavigate();

  useEffect(() => {
    const emailsArray = receiverEmails.split("\n").map((email) => email.trim());
    const uniqueEmails = new Set(emailsArray);
    setTotalMails(uniqueEmails.size);
    setDuplicateEmails([
      ...emailsArray.filter(
        (email, index) => emailsArray.indexOf(email) !== index
      ),
    ]);
  }, [receiverEmails]);

  const handleSendEmails = async (e) => {
    setLoading(true);
    e.preventDefault();

    const emailsArray = receiverEmails.split("\n").map((email) => email.trim());
    const uniqueEmails = new Set(emailsArray);

    if (uniqueEmails.size !== emailsArray.length) {
      alert("Duplicate emails found. Please remove duplicates.");
      setLoading(false);
      return;
    }

    setSuccessCount(0);
    setFailedCount(0);

    try {
      const success = await sendDynamicEmailsSwift(
        Array.from(uniqueEmails),
        subject,
        body,
        {
          headers: {
            "Content-Type": "text/html",
          },
        }
      );

      if (success) {
        setSuccessCount(uniqueEmails.size);
        // console.log(uniqueEmails, "SENT NOTI");
      } else {
        setFailedCount(uniqueEmails.size);
        console.log(uniqueEmails, "SENT NOTI");
      }

      swal({
        title: "Emails Sent",
        text: `Total Emails sent: ${uniqueEmails.size} || Total Email Success ${successCount} || Total Failed ${failedCount}`,
        icon: "success",
        button: "Close",
      });

      setBody("");
      setSubject("");
      setReceiverEmails("");
    } catch (error) {
      console.error("Error sending emails:", error);
    } finally {
      setLoading(false);
    }
  };

  const removeDuplicate = (emailToRemove) => {
    const updatedEmails = receiverEmails
      .split("\n")
      .filter((email) => email.trim() !== emailToRemove.trim())
      .join("\n");
    setReceiverEmails(updatedEmails);
  };

  const removeAllDuplicates = () => {
    const uniqueEmails = Array.from(
      new Set(receiverEmails.split("\n").map((email) => email.trim()))
    );
    setReceiverEmails(uniqueEmails.join("\n"));
  };

  const getUser = JSON.parse(localStorage.getItem("adminData"));
  console.log(getUser, "USER DETAIL");
  const userId = getUser?.user_id;

  // group name action

  const createGroup = async () => {
    try {
      const normalizedEmails = bulkEmails
        .split(/\s+/)
        .filter((email) => email.trim() !== "")
        .join(",");

      if (!normalizedEmails) {
        swal({
          title: "No Emails Provided",
          text: "Please enter email addresses.",
          icon: "warning",
          button: "Close",
        });
        return;
      }

      let data = {
        group_name: groupName,
        emails: normalizedEmails,
      };
      let configure = {
        // url: `http://172.17.18.255:8080/exam_sch/api/group_list/?user_id=${userId}`,
        url: `${config.BASE_URL}exam_sch/api/group_list/?user_id=${userId}`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data,
      };
      const request = await axios(configure);
      console.log(request, "POST grp");
      if (request.status === 201) {
        const statusCode = request?.status;
        if (statusCode === 201) {
          swal({
            title: "Group Created",
            text: `${request?.data?.group_name}  is ${request?.statusText}`,
            icon: "success",
            button: "Close",
          });
          setGroupLoad(false);
          setGroupName("");
          setGroupEmail("");
          setBulkEmails("");
        } else if (statusCode === 400) {
          swal({
            title: "Group Already Exists",
            text: `${request?.response?.data?.group_name}`,
            icon: "warning",
            button: "Close",
          });
          setGroupLoad(false);
          setGroupName("");
          setGroupEmail("");
        } else {
          swal({
            title: "Something went wrong",
            text: `${request?.statusText}`,
            icon: "warning",
            button: "Close",
          });
          setGroupLoad(false);
          setGroupName("");
          setGroupEmail("");
        }
      }
    } catch (error) {
      console.log(error, "ERORRRRR");
      swal({
        title: `Oops!`,
        text: `${error?.response?.data?.group_name}`,
        icon: "warning",
        button: "Close",
      });
    }
  };

  const handlePostGroup = async (e) => {
    setGroupLoad(true);
    e.preventDefault();
    try {
      await createGroup(groupName, groupEmail);
      setGroupLoad(false);
    } catch (error) {}
    setGroupLoad(false);
  };

  const fetchGroups = async () => {
    try {
      let configure = {
        // url: `http://172.17.18.255:8080/exam_sch/api/group_list/?user_id=${userId}`,
        url: `${config.BASE_URL}exam_sch/api/group_list/?user_id=${userId}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        method: "GET",
      };
      const response = await axios(configure);
      console.log(response, "RESPONSE GROUP");
      setGroupCount(response?.data?.length);
      setGroups(response?.data);
    } catch (error) {
      console.error("Error fetching groups:", error);
    }
  };

  // open drawer

  const showDrawer = () => {
    fetchGroups();
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    fetchGroups();
  }, []);

  const scrollDown = () => {
    window.scrollTo({
      top: window.innerHeight * 1.2,
      behavior: "smooth",
    });
  };

  // delete group

  const deleteGroup = async (groupId) => {
    setDeleteLoad(true);
    try {
      let configure = {
        // url: `http://172.17.18.255:8080/exam_sch/api/group_detail/?user_id=${userId}&group_id=${groupId}`,
        url: `${config.BASE_URL}exam_sch/api/group_detail/?user_id=${userId}&group_id=${groupId}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        method: "DELETE",
      };
      await axios(configure);

      swal({
        title: `Group Deleted!`,

        icon: "success",
        button: "Close",
      });

      setGroups(groups.filter((group) => group.group_id !== groupId));
      setDeleteLoad(false);
      // setGroups(response?.data);
    } catch (error) {
      console.error("Error deleting group:", error);
      setDeleteLoad(false);
      swal({
        title: `Oops!`,
        text: `${error?.message}`,
        icon: "warning",
        button: "Close",
      });
    }
  };

  const handleDelete = async (groupId) => {
    await deleteGroup(groupId);
  };

  // get grp detail

  const getGroupDetail = async (id) => {
    try {
      let configure = {
        // url: `http://172.17.18.255:8080/exam_sch/api/group_detail/?user_id=${userId}&group_d=${id}`,
        url: `${config.BASE_URL}exam_sch/api/group_detail/?user_id=${userId}&group_id=${id}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        method: "GET",
      };

      const detailGroup = await axios(configure);
      console.log(detailGroup, "DETAIL IS HERE");
      setMapDetail(detailGroup?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditGroup = async (id) => {
    // console.log("CLICKED");
    try {
      await getGroupDetail(id);
      // window.location.href = `/edit/${id}`;
      navigate(`/edit/${id}`);
    } catch (error) {}
  };

  const handleSelectChange = (value) => {
    setSelectedOption(value);
    setReceiverEmails("");

    let emails = [];
    for (let v of value) {
      emails.push(
        groups
          .filter((item) => (v == item.group_id ? item.emails : ""))
          ?.map((item) => item.emails?.replace(/[\,]/g, "\n"))
      );
    }

    // console.log({ emails });
    setReceiverEmails(emails.join("\n"));
    // console.log({ value });
  };

  // create group validation
  const validateEmail = (email) => {
    const regex =
      /^[a-zA-Z0-9._%+-]+@(gmail\.com|yahoo\.com|hotmail\.com|cumail\.in|gmail\.in|yahoo\.in|hotmail\.in|cumail\.com|\.[a-zA-Z]{2})$/i;

    return regex.test(email);
  };
  useEffect(() => {
    const emails = bulkEmails.split("\n").map((email) => email.trim());
    const duplicates = emails.filter(
      (email, index) => emails.indexOf(email) !== index
    );
    setDuplicateEmailsGrp(duplicates);
    const wrong = emails.filter((email) => !validateEmail(email));
    setWrongEmails(wrong);
  }, [bulkEmails]);

  const removeDuplicates = () => {
    const emails = bulkEmails.split("\n").map((email) => email.trim());
    const uniqueEmails = Array.from(new Set(emails));
    setBulkEmails(uniqueEmails.join("\n"));
  };

  const handleCorrection = (index, correctedEmail) => {
    const updatedWrongEmails = [...wrongEmails];
    updatedWrongEmails.splice(index, 1, correctedEmail);
    setWrongEmails(updatedWrongEmails);

    const updatedBulkEmails = bulkEmails.replace(
      wrongEmails[index],
      correctedEmail
    );
    setBulkEmails(updatedBulkEmails);
  };

  return (
    <>
      {getUser?.user_role_id === 2 ? null : <SideBar />}
      {loading === true ? (
        <Holdon />
      ) : (
        <>
          <div
            style={{
              width: "100vw",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <h3>Create Group</h3>
          </div>

          <div style={{ marginTop: "-30px" }} className="body12">
            <div className="container123456">
              <div>
                <div class="breadcrumb flat">
                  <Link to="/Anonymous">Back</Link>
                  <a
                    style={{ backgroundColor: "#1F2937", color: "white" }}
                    href="#"
                    class="active"
                  >
                    Create Group
                  </a>
                </div>
              </div>
              <div className="wrapper123 animated bounceInLeft">
                <div
                  style={{ width: "30vw", backgroundColor: "#1F2937" }}
                  className="company-info-list"
                >
                  <h3 style={{ color: "wheat", fontWeight: "200" }}>
                    Create Group
                  </h3>
                  <p style={{ color: "white" }}>
                    A "create group" feature allows user with registered email
                    addresses of students to form unique groups. When you choose
                    a group, the students within it are selected for email
                    distribution. Users can create multiple groups comprising
                    different students, such as "MBA sem1" or "BBA sem 3,"
                    providing clarity to the sender about the composition of
                    each group.
                    <br />
                    <span
                      style={{
                        color: "#FC4100",

                        letterSpacing: "1px",
                      }}
                    >
                      NOTE:- Incorrect emails will be highlighted directly
                      beneath the email you've pasted. You can then correct them
                      in either the "Paste Email" box or the "Wrong Emails" box.
                    </span>
                  </p>
                </div>
                <div className="contact">
                  <form>
                    <p className="full">
                      <label>Group Name</label>
                      <input
                        // style={{ width: "auto" }}
                        type="text"
                        placeholder="Must be a unique group name"
                        required
                        value={groupName}
                        onChange={(e) => setGroupName(e.target.value)}
                      />
                    </p>
                    <p style={{ marginTop: "-10px" }} className="full">
                      <label>Enter Emails</label>
                      <textarea
                        rows={5}
                        placeholder="Enter/Paste emails... (Each per line)"
                        value={bulkEmails}
                        onChange={(e) => setBulkEmails(e.target.value)}
                      />
                      {duplicateEmailsGrp.length > 0 && (
                        <p style={{ color: "red" }}>
                          Duplicate emails: {duplicateEmailsGrp.join(", ")}
                        </p>
                      )}
                    </p>
                    {duplicateEmailsGrp.length > 0 && (
                      <p className="full">
                        <button type="button" onClick={removeDuplicates}>
                          Remove Duplicates
                        </button>
                      </p>
                    )}

                    <p
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        width: "30vw",
                      }}
                    >
                      {wrongEmails.length > 0 &&
                        wrongEmails.map((email, index) => (
                          <div>
                            <div>
                              <input
                                style={{
                                  height: "30px",
                                  width: "auto",
                                  border: "none",
                                  color: "red",
                                }}
                                placeholder="Wrong Emails"
                                key={index}
                                type="text"
                                value={email}
                                onChange={(e) =>
                                  handleCorrection(index, e.target.value)
                                }
                              />
                            </div>
                          </div>
                        ))}
                    </p>
                  </form>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "10",
                      justifyContent: "space-around",
                      width: "30vw",
                      marginTop: "20px",
                    }}
                  >
                    <div>
                      <button
                        style={{
                          backgroundColor: "#1F2937",
                          fontWeight: "200",
                        }}
                        onClick={showDrawer}
                      >
                        View {groupCount} Group
                      </button>
                    </div>
                    <div>
                      <button
                        style={{
                          backgroundColor: "#1F2937",
                          fontWeight: "200",
                        }}
                        onClick={handlePostGroup}
                        type="submit"
                      >
                        Create Group
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Drawer width={720} title="View Group" onClose={onClose} open={open}>
            <>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div>
                  <p>Total Group : {groupCount}</p>
                </div>
              </div>

              <table>
                <thead>
                  <tr>
                    <th>
                      <label>S.No</label>
                    </th>
                    <th>
                      <label>Group Name</label>
                    </th>
                    <th>
                      <label>Emails count</label>
                    </th>
                    <th>
                      <label>Edit</label>
                    </th>
                    <th>
                      <label>Delete</label>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {groups &&
                    groups?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td data-label="Invoice">{index + 1}</td>
                          <td data-label="Details">{item?.group_name}</td>
                          <td data-label="Due Date">
                            {item?.emails.split(",").length}
                          </td>
                          <td data-label="Payment">
                            <EditOutlined
                              onClick={() => handleEditGroup(item?.group_id)}
                            />
                          </td>
                          <td data-label="Payment">
                            <DeleteOutlined
                              onClick={() => handleDelete(item?.group_id)}
                            />
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </>
          </Drawer>
        </>
      )}
    </>
  );
};

export default CreateGroup;
