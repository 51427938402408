import React, { useEffect, useState } from "react";
import SideBar from "./SideBar";
import { CaretDownOutlined } from "@ant-design/icons";
import { postSemester } from "../../redux/slices/AddUser/postSemester";
import { postRole } from "../../redux/slices/AddUser/postRole";
import MenuSpinner from "../CommonComponent/MenuSpinner";
import { postSession } from "../../redux/slices/AddUser/PostSession";
import { postGender } from "../../redux/slices/AddUser/PostGender";
import { Drawer, Popover } from "antd";
import viewrole from "../assets/images/viewrole.gif";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

const convertToRoman = (num) => {
  const romanNumerals = {
    1: "I",
    4: "IV",
    5: "V",
    9: "IX",
    10: "X",
    40: "XL",
    50: "L",
    90: "XC",
    100: "C",
  };

  let result = "";
  Object.keys(romanNumerals)
    .reverse()
    .forEach((value) => {
      while (num >= value) {
        result += romanNumerals[value];
        num -= value;
      }
    });

  return result;
};

const MenuItems = () => {

  // <this is for drawer 


  const [open, setOpen] = useState(false);
  const [openGender, setOpenGender] = useState(false);
  const [openSession, setOpenSession] = useState(false);
  const [openPL, setOpenPL] = useState(false);
  const [openSem, setOpenSem] = useState(false);
  const [openPro, setOpenPro] = useState(false);


// this is up to drawer 



  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingSemester, setLoadingSemester] = useState(false);
  const [loadingSession, setLoadingSession] = useState(false);
  const [loadingGender, setLoadingGender] = useState(false);

  const [showAccordian, setShowAccordian] = useState(false);
  const [showDepartment, setShowDepartment] = useState(false);
  const [showGender, setShowGender] = useState(false);
  const [showSession, setShowSession] = useState(false);
  const [showPL, setShowPL] = useState(false);
  const [showSemester, setShowSemester] = useState(false);
  const [showProg, setShowProg] = useState(false);
  const [showSub, setShowSub] = useState(false);
  const [showSpecial, setShowSpecial] = useState(false);
  const [showEle, setShowEle] = useState(false);

  const [semesterID, setSemesterId] = useState("");
  const [semesterName, setSemesterName] = useState("");

  const [startYear, setStartYear] = useState("");
  const [endYear, setEndYear] = useState("");
  const [startMonth, setStartMonth] = useState("");
  const [endMonth, setEndMonth] = useState("");

  const [roleName, setRoleName] = useState("");
  const [isStatus, setIsStatus] = useState("");

  const [genderName, setGenderName] = useState("");

  // convert to roman
  const [romanNumeral, setRomanNumeral] = useState("");

  // this is for drawer table 


  const [showRole, setShowRole] = useState([]);
  const [ sGender, setSGender ] = useState([]);
  const [ sSession, setSession ] = useState([]);
  const [ sPL, setPL ] = useState([]);
  const [ sSem, setSem ] = useState([]);
  const [ sPro, setPro ] = useState([]);


  const handleSemesterIdChange = (e) => {
    const inputVal = e.target.value;
    setSemesterId(inputVal);
    const romanNum = convertToRoman(parseInt(inputVal, 10));
    setSemesterName(romanNum);
  };
  const handleClickRole = () => {
    setShowAccordian(!showAccordian);
  };

  const handleClickDepartment = () => {
    setShowDepartment(!showDepartment);
  };
  const handleClickGender = () => {
    setShowGender(!showGender);
  };
  const handleClickSession = () => {
    setShowSession(!showSession);
  };
  const handleClickPL = () => {
    setShowPL(!showPL);
  };
  const handleClickSemester = () => {
    setShowSemester(!showSemester);
  };
  const handleClickProg = () => {
    setShowProg(!showProg);
  };
  const handleClickSub = () => {
    setShowSub(!showSub);
  };
  const handleClickSpec = () => {
    setShowSpecial(!showSpecial);
  };
  const handleClickElect = () => {
    setShowEle(!showEle);
  };

  const addSemester = async (e) => {
    e.preventDefault();
    setLoadingSemester(true);

    try {
      await postSemester(semesterID, semesterName);
    } finally {
      setLoadingSemester(false);
    }
  };

  const addRole = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await postRole(roleName, isStatus);
    } finally {
      setLoading(false);
    }
  };

  const addSession = async (e) => {
    e.preventDefault();
    setLoadingSession(true);
  
    try {
    
      await postSession(startYear, endYear, startMonth, endMonth);
    } finally {
      
      setLoadingSession(false);
    }
  };

  const addGender = async (e) => {
    e.preventDefault();
    setLoadingGender(true);
    try {
      await postGender(genderName);
    } finally {
      setLoadingGender(false);
    }
  };

  const token = JSON.parse(localStorage.getItem("slotbook"));
 
  const accessToken = token?.accessToken;

  const getRoleName = async () => {
    const roleResponse = await fetch(
      // "https://apipostman.onlinecu.in/exam_sch/api/roles_create/"
      "https://apipostman.onlinecu.in/exam_sch/api/roles_create/", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const roleResult = await roleResponse.json();
   
    setShowRole(roleResult);
  };

  const getGender = async () => {
    const genderResponse = await fetch(
      // "https://apipostman.onlinecu.in/exam_sch/api/genders_create/"
      "https://apipostman.onlinecu.in/exam_sch/api/genders_create/", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const genderResult = await genderResponse.json();
   
    setSGender(genderResult);

  };

  const getSession = async () => {
    // const sessionResponse = await fetch("https://apipostman.onlinecu.in/exam_sch/api/sessions_list/");
    const sessionResponse = await fetch("https://apipostman.onlinecu.in/exam_sch/api/sessions_list/", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const resultSession = await sessionResponse.json();
   
    setSession(resultSession);

  }

  const getProgramLev = async () => {
    // const progRes = await fetch("https://apipostman.onlinecu.in/exam_sch/api/program_level_list/");
    const progRes = await fetch("https://apipostman.onlinecu.in/exam_sch/api/program_level_list/", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const resultProL = await progRes.json();
    setPL(resultProL)
   
  }
  const getSemester = async () => {
    // const semRes = await fetch("https://apipostman.onlinecu.in/exam_sch/api/semester_list/");
    const semRes = await fetch("https://apipostman.onlinecu.in/exam_sch/api/semester_list/", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const resultSem = await semRes.json();
    setSem(resultSem);
 
  }

  const getProg = async () => {
    // const proRes = await fetch("https://apipostman.onlinecu.in/exam_sch/api/program_list/");
    const proRes = await fetch("https://apipostman.onlinecu.in/exam_sch/api/program_list/", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const resultPro = await proRes.json();
    setPro(resultPro);
 
  }



  const onGridReady = (params) => {
    params.api.sizeColumnsToFit();
  };

  const onSearchInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const defaultColDef = {
    sortable: true,
    filter: true,
  };

  useEffect(() => {

    getRoleName();
    getGender();
    getSession();
    getProgramLev();
    getSemester();
    getProg();

  }, []);

  const showDrawer = () => {
    setOpen(true);
  };
  const showDrawerForGender = () => {
    setOpenGender(true);
  };

  const showDrawerForSession = () => {
    setOpenSession(true)
  }

  const showDrawerForPL = () => {
    setOpenPL(true)
  }
  const showDrawerForSem = () => {
    setOpenSem(true)
  }

  const showDrawerForPro = () => {
    setOpenPro(true)
  }


  const onClose = () => {
    setOpen(false);
    setOpenGender(false);
    setOpenSession(false)
    setOpenPL(false)
    setOpenSem(false)
    setOpenPro(false);
  };

  const columnRole = [
    { headerName: "Role ID", field: "role_id" },
    { headerName: "Role Name", field: "role_name" },
    { headerName: "Status", field: "status" },
    { headerName: "Created At", field: "created_at" },
    { headerName: "Updated At", field: "updated_at" },
  ];

  const columnGender = [
    { headerName: "Gender ID", field: "gender_id" },
    { headerName: "Gender Name", field: "gender_name" },
    { headerName: "Status", field: "gender_status" },
  ];

  const columnSession = [
    {headerName: "Start Year", field:"start_year"},
    {headerName: "Start Month", field:"start_month"},
    {headerName: "End Year", field:"end_year"},
    {headerName:"End Month", field: "end_month"},
  ]

  const columnProgLev = [
    {headerName:"Program ID", field:"prog_level_id"},
    {headerName:"Program Name", field:"prog_level_name"},
    {headerName:"Program Status", field:"prog_status"}
  ]

  const columnSem = [
    {headerName:"Semester ID", field:"semester_id"},
    {headerName:"Semester Name", field:"semester_name"},
  ]

  const columnPro = [
    {headerName:"Program ID", field:"program_id"},
    {headerName:"Program Name", field:"program_name"},
    {headerName:"Program Code", field:"program_code"},
    {headerName:"Program Level", field:"prog_level"}
  ]

  

  return (
    <div>
      <Drawer
        width="800px"
        title="Existing Role "
        onClose={onClose}
        open={open}
      >
        <input
          type="text"
          placeholder="Search..."
          onChange={onSearchInputChange}
          style={{ marginBottom: "10px", width: "400px", borderRadius: "5px" }}
          className="slot-button"
        />
        <div
          className="ag-theme-alpine"
          style={{ height: "100vh", width: "100%" }}
        >
          <AgGridReact
            rowData={showRole}
            columnDefs={columnRole}
            pagination={true}
            paginationPageSize={10}
            onGridReady={onGridReady}
            defaultColDef={defaultColDef}
            quickFilterText={searchTerm}
          />

        
        </div>
      </Drawer>

      <Drawer
        width="800px"
        title="Existing Gender "
        onClose={onClose}
        open={openGender}
      >
        <input
          type="text"
          placeholder="Search..."
          onChange={onSearchInputChange}
          style={{ marginBottom: "10px", width: "400px", borderRadius: "5px" }}
          className="slot-button"
        />
        <div
          className="ag-theme-alpine"
          style={{ height: "100vh", width: "100%" }}
        >
          <AgGridReact
            rowData={sGender}
            columnDefs={columnGender}
            pagination={true}
            paginationPageSize={10}
            onGridReady={onGridReady}
            defaultColDef={defaultColDef}
            quickFilterText={searchTerm}
          />
        </div>
      </Drawer>

      <Drawer
        width="800px"
        title="Existing Session "
        onClose={onClose}
        open={openSession}
      >
        <input
          type="text"
          placeholder="Search..."
          onChange={onSearchInputChange}
          style={{ marginBottom: "10px", width: "400px", borderRadius: "5px" }}
          className="slot-button"
        />
        <div
          className="ag-theme-alpine"
          style={{ height: "100vh", width: "100%" }}
        >
          <AgGridReact
            rowData={sSession}
            columnDefs={columnSession}
            pagination={true}
            paginationPageSize={10}
            onGridReady={onGridReady}
            defaultColDef={defaultColDef}
            quickFilterText={searchTerm}
          />
        </div>
      </Drawer>

      <Drawer
        width="800px"
        title="Existing Session "
        onClose={onClose}
        open={openPL}
      >
        <input
          type="text"
          placeholder="Search..."
          onChange={onSearchInputChange}
          style={{ marginBottom: "10px", width: "400px", borderRadius: "5px" }}
          className="slot-button"
        />
        <div
          className="ag-theme-alpine"
          style={{ height: "100vh", width: "100%" }}
        >
          <AgGridReact
            rowData={sPL}
            columnDefs={columnProgLev}
            pagination={true}
            paginationPageSize={10}
            onGridReady={onGridReady}
            defaultColDef={defaultColDef}
            quickFilterText={searchTerm}
          />
        </div>
      </Drawer>

      <Drawer
        width="800px"
        title="Existing Semester "
        onClose={onClose}
        open={openSem}
      >
        <input
          type="text"
          placeholder="Search..."
          onChange={onSearchInputChange}
          style={{ marginBottom: "10px", width: "400px", borderRadius: "5px" }}
          className="slot-button"
        />
        <div
          className="ag-theme-alpine"
          style={{ height: "100vh", width: "100%" }}
        >
          <AgGridReact
            rowData={sSem}
            columnDefs={columnSem}
            pagination={true}
            paginationPageSize={10}
            onGridReady={onGridReady}
            defaultColDef={defaultColDef}
            quickFilterText={searchTerm}
          />
        </div>
      </Drawer>

      <Drawer
        width="800px"
        title="Existing Program "
        onClose={onClose}
        open={openPro}
      >
        <input
          type="text"
          placeholder="Search..."
          onChange={onSearchInputChange}
          style={{ marginBottom: "10px", width: "400px", borderRadius: "5px" }}
          className="slot-button"
        />
        <div
          className="ag-theme-alpine"
          style={{ height: "100vh", width: "100%" }}
        >
          <AgGridReact
            rowData={sPro}
            columnDefs={columnPro}
            pagination={true}
            paginationPageSize={10}
            onGridReady={onGridReady}
            defaultColDef={defaultColDef}
            quickFilterText={searchTerm}
          />
        </div>
      </Drawer>

      <SideBar />

      <div className="accordian">
        <h2> Exam Settings</h2>

        <div className="accordian-header" onClick={handleClickRole}>
          <p className="role-head"> Add Role : </p>

          <div
        
            className="accordian-icon"
          >
            <div>
              <CaretDownOutlined />
            </div>
            <div onClick={showDrawer} style={{ marginLeft: "10px" }}>
              <h4>View</h4>
            </div>
          </div>
        </div>

        {loading === true ? (
          <MenuSpinner />
        ) : (
          <>
            {" "}
            {showAccordian && (
              <div className="accordian-body">
                <form onSubmit={addRole} className="input-">
                  <label htmlFor="programLevel">Status</label>

                  <select
                    className="input-field"
                    required
                    id="status"
                    value={isStatus}
                    onChange={(e) => setIsStatus(e.target.value)}
                  >
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </select>

                  <label htmlFor="studentName">Role Name:</label>

                  <input
                    placeholder="Role Name"
                    className="input-field"
                    type="text"
                    id="roleName"
                    required
                    value={roleName}
                    onChange={(e) => setRoleName(e.target.value)}
                  />
                  <div className="button">
                    <button>Add Role</button>
                  </div>
                </form>
              </div>
            )}
          </>
        )}

        <div className="accordian-header" onClick={handleClickDepartment}>
          <p className="role-head">Add Department : </p>
          <div
           
             className="accordian-icon">
            <div>
            <CaretDownOutlined />

            </div>
            <div>
            <div onClick={showDrawer} style={{ marginLeft: "10px" }}>
              <h4>View</h4>
            </div>
            </div>
          </div>
        </div>
        {showDepartment && (
          <div className="accordian-body">
            <div className="input-">
              <label htmlFor="studentName">Department Name:</label>
              <input
                placeholder="Department Name"
                className="input-field"
                type="text"
                id="roleName"
                required
              />
            </div>
            <div className="button">
              <button>Add Department</button>
            </div>
          </div>
        )}

        <div className="accordian-header" onClick={handleClickGender}>
          <p className="role-head"> Add Gender : </p>
          <div  className="accordian-icon">
            <div>
            <CaretDownOutlined />

            </div>
            <div>
            <div onClick={showDrawerForGender} style={{ marginLeft: "10px" }}>
              <h4>View</h4>
            </div>
            </div>
          </div>
        </div>
        {loadingGender === true ? (
          <MenuSpinner />
        ) : (
          <>
            {showGender && (
              <div className="accordian-body">
                <form onSubmit={addGender} className="input-">
                  <label htmlFor="studentName">Gender Name:</label>
                  <input
                    placeholder="Gender Name"
                    className="input-field"
                    type="text"
                    id="genderName"
                    value={genderName}
                    required
                    onChange={(e) => setGenderName(e.target.value)}
                  />
                  <div className="button">
                    <button>Add Gender</button>
                  </div>
                </form>
              </div>
            )}
          </>
        )}

        <div className="accordian-header" onClick={handleClickSession}>
          <p className="role-head"> Add Session : </p>
          <div className="accordian-icon">
            <div>
            <CaretDownOutlined />

            </div>
            <div>
            <div onClick={showDrawerForSession} style={{ marginLeft: "10px" }}>
              <h4>View</h4>
            </div>
            </div>
          </div>
        </div>
        {loadingSession === true ? (
          <MenuSpinner />
        ) : (
          <>
            {showSession && (
              <div className="accordian-body">
                <form onSubmit={addSession} className="input-">
                  <label htmlFor="studentName">Session :</label>
                  <div style={{ display: "flex" }}>
                    {" "}
                    <input
                      style={{ marginRight: "5px" }}
                      placeholder="Start Year"
                      className="input-field"
                      type="text"
                      id="startYear"
                      value={startYear}
                      onChange={(e) => setStartYear(e.target.value)}
                      required
                      maxLength={4}
                    />
                    <input
                      placeholder="End Year"
                      className="input-field"
                      type="text"
                      id="endYear"
                      value={endYear}
                      onChange={(e) => setEndYear(e.target.value)}
                      required
                      maxLength={4}
                    />
                  </div>
                  <div style={{ display: "flex" }}>
                    <select
                      style={{ marginRight: "5px" }}
                      className="input-field"
                      required
                      id="startMonth"
                      value={startMonth}
                      onChange={(e) => setStartMonth(e.target.value)}
                    >
                      <option key="defaulr" value={0}>
                        {" "}
                        Start Month{" "}
                      </option>
                      <option>01</option>
                      <option>07</option>
                    </select>
                    <select
                      className="input-field"
                      required
                      id="endMonth"
                      value={endMonth}
                      onChange={(e) => setEndMonth(e.target.value)}
                    >
                      <option key="defaulr" value={0}>
                        {" "}
                        End Month{" "}
                      </option>

                      <option>05</option>
                      <option>12</option>
                    </select>
                  </div>
                  <div className="button">
                    <button>Add Session</button>
                  </div>
                </form>
              </div>
            )}
          </>
        )}

        <div className="accordian-header" onClick={handleClickPL}>
          <p className="role-head"> Add Program Level : </p>
          <div className="accordian-icon">
            <div>
            <CaretDownOutlined />

            </div>
            <div>
            <div onClick={showDrawerForPL} style={{ marginLeft: "10px" }}>
              <h4>View</h4>
            </div>
            </div>
          </div>
        </div>
        {showPL && (
          <div className="accordian-body">
            <div className="input-">
              <label htmlFor="studentName">Program Level Name:</label>
              <input
                placeholder="Program Level Name"
                className="input-field"
                type="text"
                id="roleName"
                required
              />
            </div>
            <div className="button">
              <button>Add Program Level</button>
            </div>
          </div>
        )}

        <div className="accordian-header" onClick={handleClickSemester}>
          <p className="role-head"> Add Semester : </p>
          <div className="accordian-icon">
            <div>
            <CaretDownOutlined />

            </div>
            <div>
            <div onClick={showDrawerForSem} style={{ marginLeft: "10px" }}>
              <h4>View</h4>
            </div>
            </div>
          </div>
        </div>
        {loadingSemester === true ? (
          <MenuSpinner />
        ) : (
          <>
            {" "}
            {showSemester && (
              <div className="accordian-body">
                <form onSubmit={addSemester} className="input-">
                  {/* ... (other form elements) */}
                  <label htmlFor="studentName">Semester Name:</label>
                  <input
                    className="input-field"
                    placeholder="e.g. 1 or 2"
                    type="text"
                    id="semesterId"
                    required
                    value={semesterID}
                    onChange={handleSemesterIdChange}
                    maxLength={1}
                  />
                  <input
                    style={{ cursor: "no-drop" }}
                    placeholder="Semester Name"
                    className="input-field"
                    type="text"
                    id="semesterName"
                    required
                    value={semesterName}
                    onChange={(e) => setSemesterName(e.target.value)}
                    disabled
                  />
                  <div className="button">
                    <button>Add Semester</button>
                  </div>
                  {/* Display the Roman numeral */}
                  {romanNumeral && <p>Roman Numeral: {romanNumeral}</p>}
                </form>
              </div>
            )}
          </>
        )}

        <div className="accordian-header" onClick={handleClickProg}>
          <p className="role-head"> Add Program : </p>
          <div className="accordian-icon">
            <div>
            <CaretDownOutlined />

            </div>
            <div>
            <div onClick={showDrawerForPro} style={{ marginLeft: "10px" }}>
              <h4>View</h4>
            </div>
            </div>
          </div>
        </div>
        {showProg && (
          <div className="accordian-body">
            <div className="input-">
              <label htmlFor="studentName">Program Name:</label>
              <input
                placeholder="Program Name"
                className="input-field"
                type="text"
                id="roleName"
                required
              />
            </div>
            <div className="button">
              <button>Add Program</button>
            </div>
          </div>
        )}

        <div className="accordian-header" onClick={handleClickSub}>
          <p className="role-head"> Add Subject : </p>
          <div className="accordian-icon">
            <CaretDownOutlined />
          </div>
        </div>
        {showSub && (
          <div className="accordian-body">
            <div className="input-">
              <label htmlFor="studentName">Subject Name:</label>
              <input
                placeholder="Subject Name"
                className="input-field"
                type="text"
                id="roleName"
                required
              />
            </div>
            <div className="button">
              <button>Add Subject</button>
            </div>
          </div>
        )}

        <div className="accordian-header" onClick={handleClickSpec}>
          <p className="role-head"> Add Specialization : </p>
          <div className="accordian-icon">
            <CaretDownOutlined />
          </div>
        </div>
        {showSpecial && (
          <div className="accordian-body">
            <div className="input-">
              <label htmlFor="studentName">Specialization Name:</label>
              <input
                placeholder="Specialization Name"
                className="input-field"
                type="text"
                id="roleName"
                required
              />
            </div>
            <div className="button">
              <button>Add Specialization</button>
            </div>
          </div>
        )}

        <div className="accordian-header" onClick={handleClickElect}>
          <p className="role-head"> Add Elective : </p>
          <div className="accordian-icon">
            <CaretDownOutlined />
          </div>
        </div>
        {showEle && (
          <div className="accordian-body">
            <div className="input-">
              <label htmlFor="studentName">Elective Name:</label>
              <input
                placeholder="Elective Name"
                className="input-field"
                type="text"
                id="roleName"
                required
              />
            </div>
            <div className="button">
              <button>Add Elective</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MenuItems;
