
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Loading from "../CommonComponent/Loading";
import Spinner from "../CommonComponent/Spinner";
import { Input } from "antd";
import config from "../../Config/applicationEnv";

const EditGroup = () => {
  const [loading, setLoading] = useState(false);
  const [gotDetail, setGotDetail] = useState(null);
  const [editedEmails, setEditedEmails] = useState("");
  const [editedGroupName, setEditedGroupName] = useState("");
  const [invalidEmails, setInvalidEmails] = useState([]); // State to hold invalid emails

  const token = JSON.parse(localStorage.getItem("slotbook"));
  const accessToken = token?.accessToken;
  const getUser = JSON.parse(localStorage.getItem("adminData"));

  const { id } = useParams();

  const getUserID = JSON.parse(localStorage.getItem("adminData"));
  const userId = getUserID?.user_id;

  const mapData = async () => {
    setLoading(true);
    try {
      let configure = {
        url: `${config.BASE_URL}exam_sch/api/group_detail/?user_id=${userId}&group_id=${id}`,
        // url: `http://172.17.18.255:8080/exam_sch/api/group_detail/?user_id=${userId}&group_id=${id}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
      const getData = await axios(configure);
      setGotDetail(getData?.data);
      setEditedGroupName(getData?.data?.group_name || "");
      setEditedEmails((getData?.data?.emails || "").replace(/,/g, "\n"));
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    mapData();
  }, []);

  const handleInputChange = (e) => {
    const newEmails = e.target.value;
    setEditedEmails(newEmails);
  };

  const updateEditedEmails = (newEmails) => {
    const existingEmails = editedEmails
      .split("\n")
      .filter((email) => email.trim() !== "");
    const uniqueEmails = [
      ...new Set([...existingEmails, ...newEmails.split("\n")]),
    ];
    setEditedEmails(uniqueEmails.join("\n"));
  };

  const validateEmails = () => {
    const invalidEmailList = editedEmails
      .split("\n")
      .filter((email) => email.trim() !== "" && !validateEmail(email));
    setInvalidEmails(invalidEmailList);
  };

  const validateEmail = (email) => {
    // Basic email validation
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const editEmails = async () => {
    setLoading(true);
    try {
      validateEmails(); // Validate emails before updating
      updateEditedEmails(editedEmails);

      let data = {
        group_name: editedGroupName,
        emails: editedEmails.replace(/\n/g, ","),
      };
      let config = {
        url: `https://apipostman.onlinecu.in/exam_sch/api/group_detail/?user_id=${userId}&group_id=${id}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        method: "PUT",
        data: data, // Send the updated data object
      };
      const response = await axios(config);
      console.log(response, "EMAILS LOOK");

      swal({
        title: `Group Updated!`,
        text: `${response?.data?.group_name} is updated successfully!`,
        icon: "success",
        button: "Close",
      });
      setLoading(false);
    } catch (error) {
      console.error("Error editing group:", error);
      setLoading(false);
      swal({
        title: `Oops!`,
        text: `${error?.message}`,
        icon: "warning",
        button: "Close",
      });
    }
  };

  return (
    <div>
      <div className="body12">
        <div className="container123456">
          <div>
            <div class="breadcrumb flat">
              <Link to="/Anonymous">Back</Link>
              <a href="#" class="active">
                Edit your group
              </a>
            </div>
          </div>
          <div className="wrapper123 animated bounceInLeft">
            <div className="company-info-list">
              <h3>Editor Info</h3>
              {/* <div className="company-info-list"> */}
              <div>
                <label>Editor Name</label>
                <Input
                  style={{ cursor: "no-drop" }}
                  placeholder={getUser?.user_name}
                  type="text"
                  name="name"
                  disabled
                />
                <label>Company</label>
                <Input
                  style={{ cursor: "no-drop" }}
                  placeholder="noreply@cuemail.in"
                  type="text"
                  name="Company"
                  disabled
                />
              </div>
              {/* </div> */}
            </div>
            {loading === true ? (
              <Loading />
            ) : (
              <>
                <div className="contact">
                  <form>
                    <p className="full">
                      <label> Group Name</label>
                      <Input
                        type="text"
                        placeholder="Must be a unique group name"
                        required
                        value={editedGroupName}
                        onChange={(e) => setEditedGroupName(e.target.value)}
                      />
                    </p>
                    <p style={{ marginTop: "-10px" }} className="full">
                      <label>Paste Emails</label>
                      <textarea
                        rows={5}
                        type="text"
                        placeholder="paste email"
                        value={editedEmails}
                        onChange={handleInputChange}
                      />
                    </p>
                    {invalidEmails.length > 0 && (
                      <div className="invalid-emails">
                        <p>Invalid Emails:</p>
                        <ul>
                          {invalidEmails.map((email, index) => (
                            <li key={index}>{email}</li>
                          ))}
                        </ul>
                      </div>
                    )}
                    <p className="full">
                      <button onClick={editEmails}>Update Group</button>
                    </p>
                  </form>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditGroup;
