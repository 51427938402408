import axios from "axios";
import { useEffect } from "react";
import swal from "sweetalert";

export const LoginManual = async (user_email, user_password) => {
  try {
    let data = {
      user_email,
      password: user_password,
    };

    let config = {
      method: "post",
      url: "https://apipostman.onlinecu.in/exam_sch/api/login",
      // url: "http://172.17.19.25:8001/exam_sch/api/login",
      data,
    };

    const apiResponse = await axios(config);

    if (apiResponse?.status === 200) {
      const userRole = apiResponse?.data?.user_role_id;
    

      if (userRole === 1) {
        swal({
          title: `${apiResponse?.data?.message}`,
          text: `${apiResponse?.data?.message}`,
          icon: "success",
          button: "Close",
        });
        window.location.href = "/";
      } else if (userRole === 2) {
        swal({
          title: `${apiResponse?.data?.message}`,
          text: `${apiResponse?.data?.message}`,
          icon: "success",
          button: "Close",
        });
        window.location.href = "/email";
      } else {
        swal({
          title: "Access Denied",
          text: "You do not have permission to access this Page.",
          icon: "error",
          button: "Close",
        });
      }

      let localStorageObj = {
        accessToken: apiResponse?.data?.access,
        roleId: apiResponse?.data?.user_role_id,
        userId: apiResponse?.data?.user_id,
        userName: apiResponse?.data?.user_name,
      };
      localStorage.setItem("slotbook", JSON.stringify(localStorageObj));

      // Combined logic for storing admin/student data based on role
      if (userRole === 1 || userRole === 2) {
        localStorage.setItem("adminData", JSON.stringify(apiResponse?.data));
      } else {
        localStorage.setItem("studentData", JSON.stringify(apiResponse?.data));
      }

      sessionStorage.setItem(
        "loginNotification",
        JSON.stringify({
          message: "Login Successful",
          description: "Welcome back!",
        })
      );


    }

    if (
      apiResponse?.data?.access === null ||
      apiResponse?.data?.access === undefined ||
      apiResponse?.data?.access === ""
    ) {
      localStorage.clear();
      window.location.href = "/login";
    }
  } catch (error) {
    swal({
      title: `${error.response?.data?.message || "Something went wrong."}`,
      text: "Something went wrong.",
      icon: "warning",
      button: "Close",
    });
  }
};

const checkLoggedIn = () => {
  const localStorageObj = JSON.parse(localStorage.getItem("slotbook"));
  if (!localStorageObj || !localStorageObj.accessToken) {
    if (window.location.pathname !== "/login") {
      window.location.href = "/login";
    }
  }
};

checkLoggedIn();
