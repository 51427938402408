


import React, { useState } from "react";
import "./TemplateEmail.css";
import swal from "sweetalert";

import SideBar from "../AdminComponent/SideBar";
import { Button, Input } from "antd";
import Holdon from "../CommonComponent/Holdon";

import { Link } from "react-router-dom";
import { DownloadOutlined } from "@ant-design/icons";
import { utils, writeFile } from "xlsx";
import ReactQuill from "react-quill";

const TemplateEmail = () => {
  const [fileUpload, setFileUpload] = useState(null);
  const [templateUpload, setTemplateUpload] = useState("");
  const [loading, setLoading] = useState(false);
  const [body, setBody] = useState("");

  const handleFileChange = (event) => {
    setFileUpload(event.target.files[0]);
  };

  const token = JSON.parse(localStorage.getItem("slotbook"));
  const getUserId = token?.userId;
  const accessToken = token?.accessToken;

  const handleTemplateChange = (value) => {
    // This allows regular typing functionality
    setTemplateUpload(value);
    setBody(value);
  };

  const sendEmail = async () => {
    if (!fileUpload || !templateUpload) {
      alert("Please upload a file and paste a template before sending.");
      return;
    }

    // Sanitize the content to replace <p> tags with <div> tags
    const sanitizedTemplateUpload = templateUpload
      .replace(/<p>/g, "<div>")
      .replace(/<\/p>/g, "</div>");

    const formData = new FormData();
    formData.append("file", fileUpload);
    formData.append("template", sanitizedTemplateUpload);
    formData.append("user_id", getUserId);

    const url = `https://apipostman.onlinecu.in/exam_sch/api/template_upload/?file=${fileUpload}&template=${encodeURIComponent(sanitizedTemplateUpload)}&user_id=${getUserId}`;

    try {
      setLoading(true);
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Disposition": "attachment; filename=" + "file",
          "Authorization": `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (response.ok) {
        const makenotification = await response.json();

        swal({
          title: ` ${makenotification?.message}  `,
          text: `Total Email ${makenotification?.total_emails_sent} || Success Emails ${makenotification?.successful_emails} || Failed Emails ${makenotification?.failed_emails}`,
          icon: "success",
          button: "Close",
        });

        setLoading(false);
      } else {
        swal({
          title: "Sent Failed",
          text: `Something went wrong`,
          icon: "error",
          button: "Close",
        });
        console.error("Failed to send email.");
        setLoading(false);
      }
    } catch (error) {
      swal({
        title: "Sent Failed",
        text: `Something went wrong`,
        icon: "error",
        button: "Close",
      });
      console.error("Error sending email:", error);
      setLoading(false);
    }
  };

  const handleDownloadSample = () => {
    const sampleText = `Dear {Student_Name},
UID: {UID}
We are happy to share with you your University ID, Username and
Password to enable you to connect to the Chandigarh University Information
Management System (CUIMS) and Learning Management System (LMS).
...`;

    const element = document.createElement("a");
    const file = new Blob([sampleText], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = "template_sample.txt";
    document.body.appendChild(element);
    element.click();
  };

  const handleDownloadTemplate = () => {
    const data = [
      [
        "Student_Name",
        "UID",
        "LMS_USER_NAME",
        "LMS_PASSWORD",
        "CUCHD_Email",
        "Cuchd_Password",
        "email",
        "subject",
      ],
      [
        "Saurabh Singh",
        "OB231mba",
        "saurabh.e15299",
        "Saurabh@15299",
        "saurabh.e15299@cumail.in",
        "Saurabh_123",
        "saurabh.e15299@cumail.in",
        "Announcement for Template",
      ],
    ];

    const wb = utils.book_new();
    const ws = utils.aoa_to_sheet(data);
    utils.book_append_sheet(wb, ws, "Template");
    writeFile(wb, "sample_data.xlsx");
  };

  const getUser = JSON.parse(localStorage.getItem("adminData"));

  return (
    <>
      {getUser?.user_role_id === 2 ? null : <SideBar />}

      {loading ? (
        <Holdon />
      ) : (
        <div className="body12">
          <div className="container123456">
            <div>
              <div className="breadcrumb flat">
                <Link to="/email">Home</Link>
                <a href="#" className="active">
                  Email Template Service
                </a>
              </div>
              <div style={{ display: "flex", gap: "20px", float: "right" }}>
                <Button
                  style={{ width: "250px" }}
                  icon={<DownloadOutlined />}
                  onClick={handleDownloadTemplate}
                >
                  Download Sample Data
                </Button>
                <Button
                  style={{ width: "250px" }}
                  icon={<DownloadOutlined />}
                  onClick={handleDownloadSample}
                >
                  Download Email Template
                </Button>
              </div>
            </div>

            <div className="wrapper123 animated bounceInLeft">
              <div className="company-info-list12">
                <h3>Sender Info</h3>
                <label>Sender Name</label>
                <Input
                  style={{ cursor: "no-drop" }}
                  placeholder={getUser?.user_name}
                  type="text"
                  name="name"
                  disabled
                />
                <label>Company</label>
                <Input
                  style={{ cursor: "no-drop" }}
                  placeholder="noreply@cuemail.in"
                  type="text"
                  name="Company"
                  disabled
                />
              </div>
              <div className="contact">
                <form onSubmit={(e) => e.preventDefault()}>
                  <p className="full">
                    <label htmlFor="file">Upload Sample Data :</label>
                    <Input
                      type="file"
                      id="file"
                      name="file"
                      required
                      accept=".xlsx"
                      onChange={handleFileChange}
                    />
                  </p>
                  <p className="full">
                    <label>Email Template</label>
                    <ReactQuill
                      className="react-quill"
                      required
                      id="template"
                      name="template"
                      value={templateUpload}
                      onChange={handleTemplateChange}
                      modules={{
                        toolbar: [
                          [{ header: [1, 2, false] }],
                          [
                            "bold",
                            "italic",
                            "underline",
                            "strike",
                            "blockquote",
                            {
                              color: [
                                "red",
                                "green",
                                "#FFF2D7",
                                "#32012F",
                                "#640D6B",
                                "#322C2B",
                                "#803D3B",
                                "#FFEC9E",
                                "#153448",
                                "#121481",
                                "#1A4D2E",
                                "#C40C0C",
                              ],
                            },
                          ],
                          [{ list: "ordered" }, { list: "bullet" }],
                          ["link", "image"],
                          ["clean"],
                        ],
                        paragraph: false,
                      }}
                      formats={[
                        "header",
                        "bold",
                        "italic",
                        "underline",
                        "strike",
                        "blockquote",
                        "list",
                        "bullet",
                        "link",
                       
                        "color",
                        "paragraph",
                      ]}
                    />
                  </p>

                  <p className="full">
                    <button type="button" onClick={sendEmail}>
                      Send
                    </button>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TemplateEmail;
