import { Navigate, Outlet } from "react-router-dom";

const AppPrivateRoute = ({ children }) => {
  const getUser = JSON.parse(localStorage.getItem("adminData"));

  if (!getUser || getUser?.user_role_id === 2) {
    return window.location.href = "/login";
  }

  return children ? children : <Outlet />;
};

export default AppPrivateRoute;
