import axios from "axios";

export const fetchProgram = async () => {
  const token = JSON.parse(localStorage.getItem("slotbook"));
 
  const accessToken = token?.accessToken;
  try {
    let config = {
      method: "get",
      url: "https://apipostman.onlinecu.in/exam_sch/api/program_level_list/",
      // url: "http://172.17.19.114:8080/exam_sch/api/user_table/create",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const apiResponse = await axios(config);
   
    const updatedData = apiResponse?.data;
    
    if (apiResponse?.status === 200) {
     
    }
    return apiResponse;
  } catch (error) {
    console.log(error, "PPP");
  }
};

// delete user

export const deleteUser = async (id) => {
  const token = JSON.parse(localStorage.getItem("slotbook"));
 
  const accessToken = token?.accessToken;
  try {
    let config = {
      method: "delete",
      url: `https://apipostman.onlinecu.in/exam_sch/api/user_table/${id}/`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const apiResponse = await axios(config);
   
    const updatedData = apiResponse?.data;
   
    return apiResponse;
  } catch (error) {
    console.log(error, "PPP");
  }
};

// update user

export const updateUser = async (id, userData) => {
  const token = JSON.parse(localStorage.getItem("slotbook"));
 
  const accessToken = token?.accessToken;
  try {
    let config = {
      method: "put",
      url: `https://apipostman.onlinecu.in/exam_sch/api/user_table/${id}/`,
      data: userData,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const apiResponse = await axios(config);
   
    const updatedData = apiResponse?.data;
  
    return apiResponse;
  } catch (error) {
   
    throw error;
  }
};

// update student

// export const updateStudent = async (id, studentData) => {
//   try {
//     let config = {
//       method: "put",
//       url: `https://apipostman.onlinecu.in/exam_sch/api/studentenrollment_list/${id}`,
//       data: studentData,
//     };

//     const apiResponse = await axios(config);
//     console.log({ apiResponse }, "Update");
//     const updatedData = apiResponse?.data;
//     console.log(updatedData);
//     return apiResponse;
//   } catch (error) {
//     console.log(error, "Error updating user");
//     throw error;
//   }
// };

export const updateStudent = async (student_id, studentData) => {
  const token = JSON.parse(localStorage.getItem("slotbook"));
 
  const accessToken = token?.accessToken;
  try {
    let config = {
      method: "put",
      url: `https://apipostman.onlinecu.in/exam_sch/api/studentenrollment_list/${student_id}`,
      data: studentData,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

   

    const apiResponse = await axios(config);

   

    return apiResponse.data; 
  } catch (error) {
    console.error("Error updating user:", error);
    throw error;
  }
};

// delete student

export const deleteStudent = async (student_id) => {
  const token = JSON.parse(localStorage.getItem("slotbook"));
 
  const accessToken = token?.accessToken;
  try {
    let config = {
      method: "delete",
      // url: `https://apipostman.onlinecu.in/exam_sch/api/studentenrollment_list/${student_id}`,
      url: `https://apipostman.onlinecu.in/exam_sch/api/studentenrollment_detail/${student_id}`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const apiResponse = await axios(config);
    
    const updatedData = apiResponse?.data;
    
    return apiResponse;
  } catch (error) {
    console.log(error, "PPP");
  }
};
