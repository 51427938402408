import React, { useState } from "react";
import {
  Button,
  Cascader,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Switch,
  TreeSelect,
} from "antd";
const StudentHomePage = () => {
  const [componentSize, setComponentSize] = useState("default");
  const onFormLayoutChange = ({ size }) => {
    setComponentSize(size);
  };
  return (
    <>
      <div className="heading">
        <h2> Book Slot</h2>
      </div>
      <div className="container-slot">
        <div className="container-form">
          <Form
            className="book-page"
            labelCol={{
              span: 4,
            }}
            wrapperCol={{
              span: 14,
            }}
            layout="horizontal"
            initialValues={{
              size: componentSize,
            }}
            onValuesChange={onFormLayoutChange}
            size={componentSize}
            style={{
              maxWidth: 600,
            }}
          >
            <Form.Item
              style={{ fontWeight: "bold", fontSize: "30px" }}
              label="Session"
            >
              <Select>
                <Select.Option value="demo">JAN 2023</Select.Option>
                <Select.Option value="demo">JULY 2023</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              style={{ fontWeight: "bold", fontSize: "30px" }}
              label="Program Level:"
            >
              <Select>
                <Select.Option value="UG">UG</Select.Option>
                <Select.Option value="PG">PG</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              style={{ fontWeight: "bold", fontSize: "30px" }}
              label="Course"
            >
              <Select>
                <Select.Option value="BBA">BBA</Select.Option>
                <Select.Option value="BCA">BCA</Select.Option>
                <Select.Option value="MBA">MBA</Select.Option>
                <Select.Option value="BCOM">BCOM</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item label="Semmester">
              <Select>
                <Select.Option value="I">I</Select.Option>
                <Select.Option value="II">II</Select.Option>
                <Select.Option value="III">III</Select.Option>
                <Select.Option value="IV">IV</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item label="Subject">
              <Select>
                <Select.Option value="demo">HR</Select.Option>
                <Select.Option value="demo">HR</Select.Option>
                <Select.Option value="demo">HR</Select.Option>
                <Select.Option value="demo">HR</Select.Option>
                <Select.Option value="demo">HR</Select.Option>
                <Select.Option value="demo">HR</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item label="Time Slot">
              <DatePicker />
            </Form.Item>

            <Form.Item className="custom-button">
              <button>Book</button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
};
export default StudentHomePage;
