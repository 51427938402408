import React, { useState } from "react";
import { ConfigProvider } from "antd";
import Select from "react-select";
import SideBar from "./SideBar";

const StudentForm = () => {
  // State variables to store form data
  const [studentName, setStudentName] = useState("");
  const [studentUid, setStudentUid] = useState("");
  const [studentEmail, setStudentEmail] = useState("");
  const [session, setSession] = useState("");
  const [programLevel, setProgramLevel] = useState("");
  const [courses, setCourses] = useState([]);
  const [semester, setSemester] = useState("");
  const [selectedSubjects, setSelectedSubjects] = useState([]);

  // Dummy data for dropdowns
  const sessionOptions = ["2022-2023", "2023-2024", "2024-2025"];
  const programLevelOptions = ["Undergraduate", "Graduate"];
  const coursesOptions = ["Course A", "Course B", "Course C"];
  const semesterOptions = ["Fall", "Spring", "Summer"];

  const subjectsOptions = [
    { value: "Math", label: "Math" },
    { value: "Physics", label: "Physics" },
    { value: "Biology", label: "Biology" },
    // Add more subjects as needed
  ];

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // You can handle form submission logic here
    console.log("Form submitted:", {
      studentName,
      studentUid,
      studentEmail,
      session,
      programLevel,
      courses,
      semester,
    });
  };

  return (

    <>
    <SideBar/>
    
    <ConfigProvider>
      <div className="login-page">
        <div className="login-container">
          <div className="login-page-left">
            <form onSubmit={handleSubmit}>
              <div className="wrapper-adduser">
                <div className="heading">
                  <div>
                    <h2>Enroll Students</h2>
                  </div>
                  <div></div>
                </div>

                <form onSubmit={handleSubmit}>
                  <div className="flex-container">
                    <div className="input-grp">
                      <label htmlFor="studentName">Student Name:</label>
                      <input
                        className="input-field"
                        type="text"
                        id="studentName"
                        value={studentName}
                        onChange={(e) => setStudentName(e.target.value)}
                      />
                    </div>

                    <div className="user-ipt">
                      <label htmlFor="studentEmail">Student Email:</label>
                      <input
                        className="input-field"
                        type="email"
                        id="studentEmail"
                        value={studentEmail}
                        onChange={(e) => setStudentEmail(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="flex-container">
                    <div className="input-grp">
                      <label htmlFor="studentUid">Student UID:</label>
                      <input
                        className="input-field"
                        type="text"
                        id="studentUid"
                        value={studentUid}
                        onChange={(e) => setStudentUid(e.target.value)}
                      />
                    </div>

                    <div className="user-ipt">
                      <label htmlFor="session">Session:</label>
                      <select
                        className="input-field"
                        id="session"
                        value={session}
                        onChange={(e) => setSession(e.target.value)}
                      >
                        {sessionOptions.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="flex-container">
                    <div className="input-grp">
                      <label htmlFor="programLevel">Program Level:</label>
                      <select
                        className="input-field"
                        id="programLevel"
                        value={programLevel}
                        onChange={(e) => setProgramLevel(e.target.value)}
                      >
                        {programLevelOptions.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="user-ipt">
                      <label htmlFor="courses">Courses:</label>
                      <select
                        className="input-field"
                        id="courses"
                        value={courses}
                        onChange={(e) =>
                          setCourses(
                            Array.from(
                              e.target.selectedOptions,
                              (option) => option.value
                            )
                          )
                        }
                      >
                        {coursesOptions.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="flex-container">
                    <div className="input-grp">
                      <label htmlFor="semester">Semester:</label>
                      <select
                        className="input-field"
                        id="semester"
                        value={semester}
                        onChange={(e) => setSemester(e.target.value)}
                      >
                        {semesterOptions.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div>
                    <label htmlFor="subjects">Subjects:</label>
                    <Select
                      className="input-field"
                      id="subjects"
                      options={subjectsOptions}
                      isMulti
                      value={selectedSubjects}
                      onChange={(selectedOptions) =>
                        setSelectedSubjects(selectedOptions)
                      }
                    />
                  </div>

                  <button type="submit">Enroll</button>
                </form>
              </div>
            </form>
          </div>
        </div>
      </div>
    </ConfigProvider>
    </>
  );
};

export default StudentForm;
