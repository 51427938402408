import React, { useEffect, useState } from "react";
import SideBar from "./AdminComponent/SideBar";
import useOnlineStatus from "./CommonComponent/useOnlineOffline";
import Spinner from "./CommonComponent/Spinner";
import usersdash from "./assets/images/usersdash.png";
import admindash from "./assets/images/admindash.png";
import emailbulk from "./assets/images/emailbulk.png";
import coursesdash from "./assets/images/coursesdash.png";
import { Button, Popover, notification } from "antd";

import CountSpinner from "./CommonComponent/CountSpinner";

import EnrolledUserProgressBar from "./AdminComponent/BarUser";
import PiechartSession2024 from "./AdminComponent/Piechart2024";
import PiechartSession2023 from "./AdminComponent/Piechart2023";
import config from "../Config/applicationEnv";
import axios from "axios";
// import AdminProfile from "./AdminComponent/AdminProfile";
import AdminProfile from "./AdminComponent/AdminProfile";
import { Link } from "react-router-dom";

const HomePage = () => {
  const [loading, setLoading] = useState(false);
  const [adminData, setAdminData] = useState("");
  const [userCount, setUserCount] = useState([]);
  const [studentCount, setStudentCount] = useState(null);
  const [roleCount, setRoleCount] = useState([]);
  const [progLevCount, setProgLevCount] = useState([]);
  const [progCount, setProgCount] = useState([]);
  const [progCountUg, setProgCountUg] = useState([]);
  const [studentByProg, setStudentByProg] = useState([]);
  const [totalEmail, setTotalEmail] = useState(0);

  const [status, setStatus] = useState("");
  // const [totalEmail, setTotalEmail] = useState(0);

  const onlineStatus = useOnlineStatus();
  if (onlineStatus === false) return <h1>You are Offline</h1>;

  const token = JSON.parse(localStorage.getItem("slotbook"));

  const accessToken = token?.accessToken;

  useEffect(() => {
    // Retrieve and display the login success notification from sessionStorage
    const loginNotification = sessionStorage.getItem("loginNotification");

    if (loginNotification) {
      const { message, description } = JSON.parse(loginNotification);

      notification.success({
        message,
        description,
      });

      // Clear the notification from sessionStorage to avoid displaying it again
      sessionStorage.removeItem("loginNotification");
    }
  }, []);

  useEffect(() => {
    const storedAdminData = localStorage.getItem("adminData");
    if (storedAdminData) {
      const parseAdminData = JSON.parse(storedAdminData.trim());
      setAdminData(parseAdminData);
    }
    setLoading(true);
  }, []);

  const getAllUserList = async () => {
    try {
      let config = {
        method: "GET",
        url: "exam_sch/api/user_table/create",
      };
      setLoading(true);
      const fetchUser = await fetch(
        "https://apipostman.onlinecu.in/exam_sch/api/user_table/create",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
        // "https://apipostman.onlinecu.in/exam_sch/api/user_table/create"
      );
      const response = await fetchUser.json();

      setUserCount(response?.results);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  // const getAdminOnly = userCount.filter(
  //   (item) => item?.user_role_name === "Admin"
  // );

  const getStudentCount = async () => {
    try {
      const fetchStudent = await axios(
        
        `${config.BASE_URL}exam_sch/api/studentenrollment_list/?prog_level_id=1`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      setStudentCount(fetchStudent?.data?.count);
    } catch (error) {
      console.log(error);
    }
  };

  const getRoleCount = async () => {
    try {
      const fetchRole = await fetch(
        "https://apipostman.onlinecu.in/exam_sch/api/roles_create/",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const response = await fetchRole.json();

      setRoleCount(response);
    } catch (error) {
      console.log(error);
    }
  };

  const getProgramLevel = async () => {
    try {
      const fetchProgramLev = await fetch(
        "https://apipostman.onlinecu.in/exam_sch/api/program_level_list/",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const response = await fetchProgramLev.json();

      setProgLevCount(response);
    } catch (error) {
      console.log(error);
    }
  };

  const getProgramSearch = async () => {
    try {
      const fetchProgtamSer = await fetch(
        "https://apipostman.onlinecu.in/exam_sch/api/program-search/?prog_level_id=2",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const response = await fetchProgtamSer.json();

      setProgCount(response);
    } catch (error) {
      console.log(error);
    }
  };

  const getProgramUg = async () => {
    try {
      const fetchProgUg = await fetch(
        // "https://apipostman.onlinecu.in/exam_sch/api/program-search/?prog_level_id=1",
        `${config.BASE_URL}exam_sch/api/program-search/?prog_level_id=1`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const response = await fetchProgUg.json();

      setProgCountUg(response);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchStudentCountBySession = async () => {
    try {
      const fetchData = await fetch(
        "https://apipostman.onlinecu.in/exam_sch/api/enrollment_count/",
        // `${config.BASE_URL}exam_sch/api/enrollment_count`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const response = await fetchData.json();

      setStudentByProg(response);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchTotalEmailCount = async () => {
    try {
      const fetchEmailapi = await fetch(
        // "https://apipostman.onlinecu.in/exam_sch/api/total_email_records",
        `${config.BASE_URL}exam_sch/api/total_email_records`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const response = await fetchEmailapi.json();

      setTotalEmail(response?.total_records);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllUserList();
    getStudentCount();
    getRoleCount();
    getProgramLevel();
    getProgramSearch();
    getProgramUg();
    fetchStudentCountBySession();
    fetchTotalEmailCount();
  }, []);

  const getUser = JSON.parse(localStorage.getItem("adminData"));

  // getting 401 will redirect you to login page because you are not authorised

  // console.log(totalEmail, "TOTAL EMAIL")
  return (
    <>
     {getUser?.user_role_id === 2 ? null :  <SideBar />}

      <>
        <div className="admin-dash">
          <div className="admin-cards">
            <Link to="/enrolled_students">
              <div
                style={{ cursor: "pointer" }}
                className="user-count usercard1"
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    width: "210px",
                    alignItems: "center",
                  }}
                >
                  <div style={{ width: "150px" }}>
                    {loading === true ? (
                      <CountSpinner />
                    ) : (
                      <>
                        {studentCount ? (
                          <p className="countp">{studentCount}</p>
                        ) : (
                          "N/A"
                        )}
                      </>
                    )}
                  </div>
                  <div>
                    <img src={usersdash} />
                  </div>
                </div>

                <div className="heading123">
                  <h3> Total enrolled Students</h3>
                </div>
              </div>
            </Link>

            <Link to="/user_table">
              <div
                style={{ cursor: "pointer" }}
                className="user-count usercard2"
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    width: "210px",
                    alignItems: "center",
                  }}
                >
                  <div style={{ width: "150px" }}>
                    {loading === true ? (
                      <CountSpinner />
                    ) : (
                      <>
                        {userCount && userCount.length >= 0 ? (
                          <p className="countp">{userCount.length}</p>
                        ) : (
                          "N/A"
                        )}
                      </>
                    )}
                  </div>
                  <div>
                    <img style={{ width: "40px" }} src={admindash} />
                  </div>
                </div>

                <div className="heading123">
                  <h3> Total enrolled Users</h3>
                </div>
              </div>
            </Link>

            {getUser?.user_role_id === 2 ? null : (
              <>
                {" "}
                <div
                  style={{ cursor: "pointer" }}
                  className="user-count usercard3"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      width: "210px",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ width: "150px" }}>
                      {loading === true ? (
                        <CountSpinner />
                      ) : (
                        <>
                          {progCount && progCount.length >= 0 ? (
                            <p className="countp">{progCount.length}</p>
                          ) : (
                            "N/A"
                          )}
                        </>
                      )}
                    </div>
                    <div>
                      <img
                        style={{ width: "40px", filter: "blur(0.8px)" }}
                        src={coursesdash}
                      />
                    </div>
                  </div>

                  <div className="heading123">
                    <h3> Total Program in PG</h3>
                  </div>
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  className="user-count usercard4"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      width: "210px",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ width: "150px" }}>
                      {loading === true ? (
                        <CountSpinner />
                      ) : (
                        <>
                          {progCountUg && progCountUg.length >= 0 ? (
                            <p className="countp">{progCountUg.length}</p>
                          ) : (
                            "N/A"
                          )}
                        </>
                      )}
                    </div>
                    <div>
                      <img
                        style={{ width: "40px", filter: "blur(0.8px)" }}
                        src={coursesdash}
                      />
                    </div>
                  </div>

                  <div className="heading123">
                    <h3> Total Program in UG</h3>
                  </div>
                </div>
              </>
            )}
            <Link to="/email-record">
              <div
                style={{ cursor: "pointer" }}
                className="user-count usercard2"
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    width: "210px",
                    alignItems: "center",
                  }}
                >
                  <div style={{ width: "150px" }}>
                    {loading === true ? (
                      <CountSpinner />
                    ) : (
                      <>
                        {totalEmail ? (
                          <p className="countp">{totalEmail}</p>
                        ) : (
                          "N/A"
                        )}
                      </>
                    )}
                  </div>
                  <div>
                    <img style={{ width: "40px" }} src={emailbulk} />
                  </div>
                </div>

                <div className="heading123">
                  <h3> Total Bulk Email</h3>
                </div>
              </div>
            </Link>
          </div>
        </div>

        {getUser?.user_role_id === 2 ? null : (
          <>
            {" "}
            <div className="dash-chart">
              <div className="session2024">
                <PiechartSession2024 />
              </div>

              <div className="user-chart">
                <EnrolledUserProgressBar />
              </div>
            </div>
            <div className="dash-chart">
              <div className="session2024">
                <PiechartSession2023 />
              </div>

              {/* <div className="user-chart admin-chart">
                <h2
                  style={{
                    display: "flex",
                    fontWeight: "bold",
                    fontSize: "20px",
                    width: "45vw",
                    justifyContent: "center",
                    fontFamily: " Poppins, sans-serif",
                  }}
                >
                  Total Admins in Slot Booking Application{" "}
                  <span
                    style={{
                      fontSize: "25px",
                      color: "red",
                      fontWeight: "bold",
                      marginLeft: "10px",
                    }}
                  >
                    {getAdminOnly?.length}
                  </span>
                </h2>

                <table className="table">
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Phone</th>
                    </tr>
                  </thead>
                  <tbody>
                    {getAdminOnly &&
                      getAdminOnly.map((item) => (
                        <>
                          <tr>
                            <td>{item?.user_name}</td>
                            <td>{item?.user_email}</td>
                            <td>{item?.user_mobile}</td>
                          </tr>
                        </>
                      ))}
                  </tbody>
                </table>
              </div> */}
            </div>
          </>
        )}

        {getUser?.user_role_id === 2 ? (
          <>
            {" "}
            <div className="home-wrap">
              <div className="wrapper-box">
                <h3>Hi {adminData?.user_name}</h3>
                <h2>
                  Welcome to{" "}
                  <span style={{ color: "#680000", fontWeight: "bold" }}>
                    Email Service
                  </span>{" "}
                </h2>
                {getUser?.user_role_id === 2 ? null : (
                  <>
                    {" "}
                    <div
                      style={{ display: "flex", justifyContent: "center" }}
                      class="frame"
                    >
                      <Link to="create-slot">
                        <button
                          style={{
                            width: "250px",
                            height: "40px",
                            fontSize: "16px",
                          }}
                        >
                          Create Slot
                        </button>
                      </Link>
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        ) : (
          ""
        )}
        <div style={{ marginTop: "-150px" }}>
          <AdminProfile />
        </div>
      </>
    </>
  );
};

export default HomePage;
