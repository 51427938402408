import { Button, Popover, Result } from "antd";
import { useEffect, useState } from "react";

import SimmerUi from "../CommonComponent/SimmerUi";

const CreatedSlots = () => {
  const [originalSlotList, setOriginalSlotList] = useState([]);
  const [slotList, setSlotList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);

  const token = JSON.parse(localStorage.getItem("slotbook"));

  const accessToken = token?.accessToken;
  

  const fetchApiForSlot = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        // `https://apipostman.onlinecu.in/exam_sch/api/slots_list/`
        `https://apipostman.onlinecu.in/exam_sch/api/slots_list/`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const result = await response.json();
      setOriginalSlotList(result);
      setSlotList(result);
      setLoading(false);
    } catch (error) {
      console.log(error, "fetchApiForSlot");
    }
  };

  useEffect(() => {
    fetchApiForSlot();
  }, []);

  const removeSingleQuotes = (subjectName) => {
    return subjectName.replace(/'/g, "");
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
    if (query.length >= 3) {
      const filteredList = originalSlotList.filter(
        (item) =>
          item.subject_name.toLowerCase().includes(query.toLowerCase()) ||
          item.session.toLowerCase().includes(query.toLowerCase())
      );

      setSlotList(filteredList);
    } else {
      setSlotList(originalSlotList);
    }
  };

  return (
    <div>
      <div className="search-slot">
        {/* <h3>Search</h3> */}
        <div style={{ display:"flex", justifyContent:"center"}}>
        <input
          style={{
            border: "1px solid black",
            width: "250px",
            height: "25px",
            borderRadius: "4px",
            padding: "3px",
            textDecoration: "none",
          }}
          placeholder="202401 or Research Methodology..."
          type="text"
          value={searchQuery}
          onChange={(e) => handleSearch(e.target.value)}
        />
        <button
          className="slot-button"
          onClick={() => handleSearch(searchQuery)}
        >
          Search
        </button>
        </div>
       
        <div>
          Total Slot created for the subjects
          <span style={{ color: "#680000", fontWeight: "bold" }}>
            {" "}
            {slotList.length}
          </span>
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <th>Slot ID</th>
            <th>Session</th>
            <th>Subject</th>
            <th>Slot List</th>
          </tr>
        </thead>
        {slotList.length === 0 ? (
          <div style={{ width:"600px"}} className="no-data">
            <Result
              status="404"
              title="404"
              subTitle="Sorry, the page you visited does not exist."
              extra={<Button type="primary">Back Home</Button>}
            />
          </div>
        ) : (
          <>
            {" "}
            <tbody>
              {loading === true ? (
                <SimmerUi />
              ) : (
                <>
                  {" "}
                  {slotList &&
                    slotList.map((item) => (
                      <tr key={item?.slot_id}>
                        <td style={{ fontWeight: "bold" }}>{item?.slot_id}</td>
                        {item?.session === "202401" ? (
                          <>
                            <td style={{ fontWeight: "bold" }}>
                              {item?.session}
                            </td>
                          </>
                        ) : (
                          <td
                            title={item?.session}
                            style={{
                              color: "white",
                              fontWeight: "bold",
                              background: "grey",
                            }}
                          >
                            {item?.session}
                          </td>
                        )}
                        <td style={{ fontWeight: "bold", cursor: "pointer" }}>
                          <Popover color="#680000" title={item?.subject_name}>
                            {removeSingleQuotes(item?.subject_name)}
                          </Popover>
                        </td>
                        <td style={{ fontWeight: "bold", cursor: "pointer" }}>
                          <Popover color="#680000" title={item.slot_list}>
                            {item?.slot_list}
                          </Popover>
                        </td>
                      </tr>
                    ))}
                </>
              )}
            </tbody>
          </>
        )}
      </table>
    </div>
  );
};

export default CreatedSlots;
