import axios from "axios";
import swal from "sweetalert";

export const postRole = async (roleName, isStatus) => {
  const token = JSON.parse(localStorage.getItem("slotbook"));
 
  const accessToken = token?.accessToken;
  try {
    let userData = {
        role_name: roleName,
        Status: isStatus,
    };
    let config = {
      method: "post",
      url: "https://apipostman.onlinecu.in/exam_sch/api/roles_create/",
      // url: "https://apipostman.onlinecu.in/exam_sch/api/roles_create/",
      data: userData,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
  
    const apiResponse = await axios(config);
   

    if (apiResponse?.status === 201) {
     
      swal({
        title: "User Role Added!",
        text: ``,
        icon: "success",
        button: "Close",
      });
    } else {
    }
  } catch (error) {
   
    swal({
      title: `${error?.response?.data?.role_name}`,
      text: "Something went wrong.",
      icon: "warning",
      button: "Close",
    });
  }
};
