
import React, { useEffect, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import config from "../../Config/applicationEnv";
import { Button, message } from "antd";
import { Link } from "react-router-dom";
import * as XLSX from "xlsx";
import "./EmailRecord.css";

const EmailRecord = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [colDefs, setColDefs] = useState([
    { field: "S.No", valueGetter: "node.rowIndex + 1" },
    { field: "user_name", headerName: "User Name" },
    { field: "receiver_email", headerName: "Receiver Email" },
    { field: "body", headerName: "Body" },
    { field: "subject", headerName: "Subject" },
    {
      field: "sent_at",
      headerName: "Sent At",
      valueGetter: (params) =>
        params?.data.sent_at
          ? new Date(params.data.sent_at).toLocaleString("en-IN", {
              timeZone: "Asia/Kolkata",
            })
          : "",
    },
    {
      field: "success",
      headerName: "Success",
      valueGetter: (params) => (params.data.success ? "Success" : "Failed"),
    },
  ]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPageCount, setTotalPageCount] = useState(0);

  const token = JSON.parse(localStorage.getItem("slotbook"));
  const id = token?.userId;
  const accessToken = token?.accessToken;

  const getAdminTable = async (pageNumber) => {
    setLoading(true);
    try {
      message.info("Loading report...");
      const year = selectedDate.getFullYear();
      const month = selectedDate.getMonth() + 1;
      const day = selectedDate.getDate();
      let url = `${config.BASE_URL}exam_sch/api/search-emails/?month=${month}&year=${year}&day=${day}&page=${pageNumber}`;

      if (token?.roleId != "1") {
        url += `&user_id=${id}`;
      }

      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const totalRecords = response.data.count;
      const recordsPerPage = response.data.results.length;
      const totalPageCount = Math.ceil(totalRecords / recordsPerPage);

      const startingSerialNumber = (pageNumber - 1) * recordsPerPage + 1;

      const updatedRowData = response.data.results.map((row, index) => ({
        ...row,
        serialNumber: startingSerialNumber + index,
      }));

      setRowData(updatedRowData);
      setLoading(false);
      message.success("Loading success");
    } catch (error) {
      setLoading(false);
      console.error("Error loading email records:", error);
      message.error("Failed to load email records. Please try again later.");
    }
  };

  useEffect(() => {
    getAdminTable(currentPage);
  }, [currentPage, selectedDate]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const renderPageNumbers = () => {
    const totalPageCount = 10000;
    const range = 5;

    const start = Math.max(1, currentPage - range);
    const end = Math.min(totalPageCount, currentPage + range);

    const pages = [];
    for (let i = start; i <= end; i++) {
      const isCurrentPage = i === currentPage;
      pages.push(
        <p
          key={i}
          onClick={() => setCurrentPage(i)}
          style={{
            display: "flex",
            backgroundColor: isCurrentPage ? "#1777FE" : "grey",
            width: "30px",
            borderRadius: "50%",
            color: "white",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            height: "30px",
          }}
        >
          {i}
        </p>
      );
    }
    return pages;
  };

  const downloadFailedData = () => {
    const failedData = rowData.filter((item) => !item.success);

    const ws = XLSX.utils.json_to_sheet(failedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "FailedData");
    XLSX.writeFile(wb, "failed_data.xlsx");
  };

  const downloadSuccessData = () => {
    const successData = rowData.filter((item) => item.success);

    const ws = XLSX.utils.json_to_sheet(successData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Success Data");
    XLSX.writeFile(wb, "Success_Data.xlsx");
  };

  const downloadReportData = () => {
    const ws = XLSX.utils.json_to_sheet(rowData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Report");
    XLSX.writeFile(wb, "Report.xlsx");
  };

  return (
    <div className="body12">
      <div className="container123456">
        <div class="breadcrumb flat">
          <Link to="/email">Home</Link>
          <a href="#" class="active">
            Email Reports
          </a>
        </div>
        <div style={{ padding: "20px" }}>
          <DatePicker selected={selectedDate} onChange={handleDateChange} />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "90vw",
            marginBottom: "30px",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "40vw",
              justifyContent: "space-around",
              float:"right"
            }}
          >
            <Button danger onClick={downloadFailedData}>
              Download Failed Emails
            </Button>
            <Button type="primary" onClick={downloadSuccessData}>
              Download Success Emails
            </Button>
            <Button type="dashed" onClick={downloadReportData}>
              Download Emails Report
            </Button>
          </div>
        </div>
        {rowData && rowData?.length > 0 ? (
          <>
            {" "}
            <div
              className="ag-theme-quartz"
              style={{
                height: "200vh",
                width: "90vw",
                marginLeft: "-120px",
              }}
            >
              <AgGridReact rowData={rowData} columnDefs={colDefs} />
            </div>
          </>
        ) : (
          <h2>No data found</h2>
        )}
        <div
          style={{
            position: "fixed",
            right: "0",
            bottom: "0",
            marginRight: "30px",
            marginBottom: "20px",
            display: "flex",
            gap: "10px",
          }}
        >
          <p
            disabled={currentPage === 1}
            onClick={() => setCurrentPage(currentPage - 1)}
            style={{ marginRight: "10px" }}
          >
            Prev
          </p>
          {renderPageNumbers()}
          <p
            disabled={currentPage === totalPageCount}
            onClick={() => setCurrentPage(currentPage + 1)}
          >
            Next
          </p>
        </div>
      </div>
    </div>
  );
};

export default EmailRecord;