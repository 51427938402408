import React from "react";
import "./Holdon.css";
import gmailIcon from "../assets/images/gmailIcon.png";
import { Link } from "react-router-dom";

const Holdon = () => {
  return (
    <div className="body-hold">
      <div className="kinetic"></div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div>
          <h2 style={{ color: "green" }}>We are sending mails...</h2>
        </div>
        <div className="gmail-ani">
          <img src={gmailIcon} />
        </div>
        <div>
          <p style={{ color: "blue" }}>
            Continue sending more emails simultaneously. Thank you for using
            CU-Postman.{" "}
          </p>
        </div>
        <Link to="/email">
          <button style={{ marginTop: "30px" }}>Send More Email</button>
        </Link>
      </div>
    </div>
  );
};

export default Holdon;
