import React, { useEffect, useState } from "react";
import { Select, Modal, Button } from "antd";
import { ConfigProvider } from "antd";
import SideBar from "./SideBar";
import { AddUserManual } from "../../redux/slices/AddUser/AddUserSliceAction";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Spinner from "../CommonComponent/Spinner";
import Loading from "../CommonComponent/Loading";
import ListOfUsers from "./ListOfUsers";
import { Link } from "react-router-dom";
import OpenModalForBulkUser from "./OpenModalForBulkUser";
import ExcelDownloadButton from "./DownloadXlsx";
import "./AddUser.css";

const AddUser = () => {
  const [loading, setLoading] = useState(false);
  const [isAdminRole, setIsAdminRole] = useState(false);

  const [roles, setRoles] = useState([]);
  const [selectedUserRole, setSelectedUserRole] = useState("");
  const [gender, setGender] = useState([]);
  const [genderSelected, setGenderSelected] = useState("");

  const [userEmail, setUserEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [unique_name, setUnique_Name] = useState("");
  const [ phone, setPhone ] = useState("");


  const [userStatus, setUserStatus] = useState("");
  const [userNameValidation, setUserNameValidation] = useState(false);
  const [userEmailValidation, setUserEmailVaildation] = useState(false);

  const [userPassword, setUserPassword] = useState("");
  const [userPasswordValid, setUserPasswordValid] = useState(false);

  const [reenteredPassword, setReenteredPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);

  const [isPasswordModalVisible, setIsPasswordModalVisible] = useState(false);
  const [isPasswordSet, setIsPasswordSet] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    try {
      await AddUserManual(
        selectedUserRole,
        userName,
        unique_name,
        userEmail,
        phone,
        genderSelected,
        userStatus,
        isAdminRole ? userPassword : undefined
      );
    } finally {
      setLoading(false);
    }
  };

  const token = JSON.parse(localStorage.getItem("slotbook"));

  const accessToken = token?.accessToken;

  useEffect(() => {
    // fetch(`https://apipostman.onlinecu.in/exam_sch/api/genders_create/`)
    fetch(`https://apipostman.onlinecu.in/exam_sch/api/genders_create/`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => response?.json())
      .then((data) => {
        setGender(data);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, []);

  useEffect(() => {
    // fetch(`https://apipostman.onlinecu.in/exam_sch/api/roles_create/`)
    fetch(`https://apipostman.onlinecu.in/exam_sch/api/roles_create/`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => response?.json())
      .then((data) => {
        setRoles(data);
      })
      .catch((error) => {
        console.error("Error fetching data user role: ", error);
      });
  }, []);

  const { Option } = Select;

  const hasSpecialCharacter = (password) => {
    const specialCharacterRegex = /[!@#$%^&*(),.?":{}|<>]/;
    return specialCharacterRegex.test(password);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const showPasswordModal = () => {
    setIsPasswordModalVisible(true);
  };

  const handlePasswordModalOk = () => {
    setIsPasswordModalVisible(false);
  };

  const handlePasswordModalCancel = () => {
    setIsPasswordModalVisible(false);
  };

  const handleReenteredPasswordChange = (value) => {
    setReenteredPassword(value);
    setPasswordsMatch(value === userPassword);
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <>
      <SideBar />

      <div>
        {loading ? (
          <Loading />
        ) : (
          <>
            <ConfigProvider>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "auto",
                }}
                className="body1234567"
              >
                <div style={{ marginTop: "150px" }} className="container-add">
                  <div className="title">Register User</div>
                  <form className="form-add" onSubmit={handleSubmit}>
                    <div className="user__details">
                      <div className="input__box">
                        <span className="details">Full Name</span>
                        <input
                          type="text"
                          placeholder="E.g: John Smith"
                          required
                          value={userName}
                          onChange={(e) => setUserName(e.target.value)}
                        />
                      </div>
                      <div className="input__box">
                        <span className="details">Username</span>
                        <input
                          type="text"
                          placeholder="johnWC98"
                          required
                          value={unique_name}
                          onChange={(e) => setUnique_Name(e.target.value)}
                        />
                      </div>
                      <div className="input__box">
                        <span className="details">Email</span>
                        <input
                          type="email"
                          placeholder="johnsmith@hotmail.com"
                          required
                          value={userEmail}
                          onChange={(e) => setUserEmail(e.target.value)}
                        />
                      </div>
                      <div className="input__box">
                        <span className="details">Phone Number</span>
                        <input
                          type="tel"
                          // pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                          placeholder="012-345-6789"
                          required
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </div>
                      <div className="input__box">
                        <span className="details">Select Gender</span>
                        <Select
                          className="input-field-ant"
                          placeholder="Select a gender"
                          value={genderSelected}
                          onChange={(value) => setGenderSelected(value)}
                          allowClear
                          required
                        >
                          {Array.isArray(gender) ? (
                            gender.map((option) => (
                              <Select.Option
                                key={option.gender_id}
                                value={option.gender_id}
                              >
                                {option.gender_name}
                              </Select.Option>
                            ))
                          ) : (
                            <Select.Option key="default" value="default">
                              No gender options available
                            </Select.Option>
                          )}
                        </Select>
                      </div>
                      <div className="input__box">
                        <span className="details">Select Status</span>
                        <Select
                          className="input-field-ant"
                          placeholder="Select a status"
                          onChange={(value) => {
                            // console.log(value, "USER STATUS");
                            setUserStatus(value);
                          }}
                          allowClear
                          value={userStatus}
                          required
                        >
                          <Option value="active">Active</Option>
                          <Option value="inactive">Inactive</Option>{" "}
                        </Select>
                      </div>
                      <div className="input__box">
                        <span className="details">Select Role</span>
                        <Select
                          className="input-field-ant"
                          placeholder="Select Role"
                          value={selectedUserRole}
                          onChange={(value) => setSelectedUserRole(value)}
                          onSelect={(value) => {
                            if (value === 1 || value === 2) {
                              setIsAdminRole(true);
                            } else {
                              setIsAdminRole(false);
                            }
                          }}
                          allowClear
                          required
                        >
                          {Array.isArray(roles) ? (
                            roles.map((option) => (
                              <Select.Option
                                key={option.role_id}
                                value={option.role_id}
                              >
                                {option.role_name}
                              </Select.Option>
                            ))
                          ) : (
                            <Select.Option key="default" value="default">
                              No roles available
                            </Select.Option>
                          )}
                        </Select>
                      </div>
                    </div>

                    <div className="user__details">
                      <div className="input-">
                        {isAdminRole && (
                          <div>
                            <span className="details">
                              Create Your Password
                            </span>

                            <div className="button">
                              <input  onClick={showPasswordModal} value="Set Password" />
                            </div>

                            <Modal
                              title="Set Password"
                              visible={isPasswordModalVisible}
                              onOk={handlePasswordModalOk}
                              onCancel={handlePasswordModalCancel}
                            >
                              <label htmlFor="reenteredPassword">
                                Password
                              </label>

                              <div className="password-input">
                                <input
                                  type={showPassword ? "text" : "password"}
                                  id="userPassword"
                                  className="input-field"
                                  placeholder="Password"
                                  required
                                  value={userPassword}
                                  onChange={(e) =>
                                    setUserPassword(e.target.value)
                                  }
                                  onMouseDown={() => setUserPasswordValid(true)}
                                  maxLength={12}
                                />
                                <div className="character-count">
                                  {userPassword.length}/12
                                </div>

                                <span
                                  className="password-toggle-icon"
                                  onClick={togglePasswordVisibility}
                                >
                                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                                </span>
                              </div>
                              {userPassword.length === 0 &&
                              userPasswordValid ? (
                                <p
                                  style={{
                                    color: "red",
                                    padding: "0px",
                                    marginTop: "-10px",
                                  }}
                                >
                                  User Password is required
                                </p>
                              ) : userPassword.length <= 5 ? (
                                <p
                                  style={{
                                    color: "red",
                                    padding: "0px",
                                    marginTop: "-10px",
                                  }}
                                >
                                  User password must be greater than 7 and less
                                  than 12 characters
                                </p>
                              ) : !hasSpecialCharacter(userPassword) ? (
                                <p
                                  style={{
                                    color: "red",
                                    padding: "0px",
                                    marginTop: "-10px",
                                  }}
                                >
                                  User password must contain at least one
                                  special character
                                </p>
                              ) : (
                                ""
                              )}

                              <label htmlFor="reenteredPassword">
                                Re-enter Password
                              </label>
                              <div className="password-input">
                                <input
                                  type={showPassword ? "text" : "password"}
                                  id="reenteredPassword"
                                  className={`input-field ${
                                    !passwordsMatch ? "passwords-not-match" : ""
                                  }`}
                                  placeholder="Re-enter Password"
                                  required
                                  maxLength={12}
                                  value={reenteredPassword}
                                  onChange={(e) =>
                                    handleReenteredPasswordChange(
                                      e.target.value
                                    )
                                  }
                                />
                                <div className="character-count">
                                  {reenteredPassword.length}/12
                                </div>

                                <span
                                  className="password-toggle-icon"
                                  onClick={togglePasswordVisibility}
                                >
                                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                                </span>
                              </div>
                              {!passwordsMatch && (
                                <p
                                  style={{
                                    color: "red",
                                    padding: "0px",
                                    marginTop: "-10px",
                                  }}
                                >
                                  Passwords do not match
                                </p>
                              )}
                            </Modal>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="button">
                      <input type="submit" value="Register" />
                    </div>
                  </form>
                </div>
                <div className="container-add-bulk">
                  <div id="uploadArea" class="upload-area">
                    <div class="upload-area__header">
                      <h1 class="upload-area__title">Bulk Upload User</h1>
                      <p class="upload-area__paragraph">
                        File should be an Excel file
                       
                      </p>
                    </div>
                    <div className="add-user-body">
                      <div>
                        <OpenModalForBulkUser />
                      </div>
                    </div>
                    <div>
                      <ExcelDownloadButton />
                    </div>
                  </div>
                </div>
              </div>
            </ConfigProvider>
          </>
        )}
      </div>
    </>
  );
};

export default AddUser;
