import React, { useEffect, useState } from "react";
import "./SendMailAno.css";
import swal from "sweetalert";
import SideBar from "./SideBar";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Input, Select, message, Button } from "antd";
import { sendDynamicEmailsSwift } from "../../redux/slices/smtpStudents/SmtpSwiftMail";
import Holdon from "../CommonComponent/Holdon";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

const SendMailAno = () => {
  const [receiverEmails, setReceiverEmails] = useState("");
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalMails, setTotalMails] = useState(0);
  const [successCount, setSuccessCount] = useState(0);
  const [failedCount, setFailedCount] = useState(0);
  const [duplicateEmails, setDuplicateEmails] = useState([]);

  const [bulkEmails, setBulkEmails] = useState("");
  const [groups, setGroups] = useState([]);

  const [groupCount, setGroupCount] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  const [duplicateEmailsGrp, setDuplicateEmailsGrp] = useState([]);
  const [wrongEmails, setWrongEmails] = useState([]);

  const [signature, setSignature] = useState([]);

  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  const token = JSON.parse(localStorage.getItem("slotbook"));
  const accessToken = token?.accessToken;
  const navigate = useNavigate();

  useEffect(() => {
    const emailsArray = receiverEmails.split("\n").map((email) => email.trim());
    const uniqueEmails = new Set(emailsArray);
    setTotalMails(uniqueEmails.size);
    setDuplicateEmails([
      ...emailsArray.filter(
        (email, index) => emailsArray.indexOf(email) !== index
      ),
    ]);
  }, [receiverEmails]);

  const handleSendEmails = async (e) => {
    setLoading(true);
    e.preventDefault();
    if (signature.length <= 0) {
      message.info("Signature field is empty");
      setLoading(false);
      return;
    }
    if (body.length <= 0) {
      message.info("Message field is empty");
      setLoading(false);
      return;
    }

    message.success("Sending emails...");
    const emailsArray = receiverEmails.split("\n").map((email) => email.trim());
    const uniqueEmails = new Set(emailsArray);

    if (uniqueEmails.size !== emailsArray.length) {
      alert("Duplicate emails found. Please remove duplicates.");
      setLoading(false);
      return;
    }

    setSuccessCount(0);
    setFailedCount(0);

    // Sanitize body by replacing <p> tags with <div> tags
    const sanitizedTemplateUpload = body
      .replace(/<p>/g, "<div>")
      .replace(/<\/p>/g, "</div>");

    try {
      const success = await sendDynamicEmailsSwift(
        Array.from(uniqueEmails),
        subject,
        `${sanitizedTemplateUpload}\n${signature}`,
        {
          headers: {
            "Content-Type": "text/html",
          },
        }
      );

      if (success) {
        setSuccessCount(uniqueEmails.size);
        message.success("Email sent successfully");
      } else {
        setFailedCount(uniqueEmails.size);
        message.error("Sending emails failed");
      }

      swal({
        title: "Emails Sent",
        icon: "success",
        button: "Close",
      });

      setBody("");
      setSubject("");
      setSignature("");
      setReceiverEmails("");
    } catch (error) {
      console.error("Error sending emails:", error);
    } finally {
      setLoading(false);
    }
  };

  const removeAllDuplicates = () => {
    const uniqueEmails = Array.from(
      new Set(receiverEmails.split("\n").map((email) => email.trim()))
    );
    setReceiverEmails(uniqueEmails.join("\n"));
    // No email sending on removing duplicates
  };

  const getUser = JSON.parse(localStorage.getItem("adminData"));
  const userId = getUser?.user_id;

  const fetchGroups = async () => {
    try {
      let config = {
        url: `https://apipostman.onlinecu.in/exam_sch/api/group_list/?user_id=${userId}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        method: "GET",
      };
      const response = await axios(config);
      console.log(response, "GROPS SELECT");
      setGroupCount(response?.data?.length);
      setGroups(response?.data);
    } catch (error) {
      console.error("Error fetching groups:", error);
    }
  };

  useEffect(() => {
    fetchGroups();
  }, []);

  const scrollDown = () => {
    navigate("/create-group");
  };

  const handleSelectChange = (value) => {
    setSelectedOption(value);
    setReceiverEmails("");

    let emails = [];
    for (let v of value) {
      emails.push(
        groups
          .filter((item) => (v === item.group_id ? item.emails : ""))
          ?.map((item) => item.emails?.replace(/[\,]/g, "\n"))
      );
    }

    setReceiverEmails(emails.join("\n"));
  };

  const validateEmail = (email) => {
    const regex =
      /^[a-zA-Z0-9._%+-]+@(gmail\.com|yahoo\.com|hotmail\.com|cumail\.in|\.[a-zA-Z]{2})$/i;

    return regex.test(email);
  };

  useEffect(() => {
    const emails = bulkEmails.split("\n").map((email) => email.trim());
    const duplicates = emails.filter(
      (email, index) => emails.indexOf(email) !== index
    );
    setDuplicateEmailsGrp(duplicates);
    const wrong = emails.filter((email) => !validateEmail(email));
    setWrongEmails(wrong);
  }, [bulkEmails]);

  return (
    <>
      {getUser?.user_role_id === 2 ? null : <SideBar />}
      {loading === true ? (
        <Holdon />
      ) : (
        <>
          <div className="body12">
            <div className="container123456">
              <div>
                <div className="breadcrumb flat">
                  <Link to="/email">Home</Link>
                  <a href="#" className="active">
                    Quick Dispatcher
                  </a>
                </div>

                <p style={{ float: "right" }} className="full">
                  <button
                    style={{ backgroundColor: "#105252", fontWeight: "200" }}
                    type="submit"
                    onClick={scrollDown}
                  >
                    Create Group
                  </button>
                </p>
              </div>

              <div className="wrapper123 animated bounceInLeft">
                <div
                  style={{ backgroundColor: "#1F2937" }}
                  className="company-info-list"
                >
                  <div>
                    <h3 style={{ color: "white", fontWeight: "200" }}>
                      Sender Info
                    </h3>
                    <label
                      style={{
                        color: "white",
                        fontWeight: "200",
                        marginBottom: "10px",
                      }}
                    >
                      Sender Name
                    </label>
                    <Input
                      style={{ cursor: "no-drop" }}
                      placeholder={getUser?.user_name}
                      type="text"
                      name="name"
                      disabled
                    />
                    <label
                      style={{
                        color: "white",
                        fontWeight: "200",
                        marginBottom: "10px",
                      }}
                    >
                      Company
                    </label>
                    <Input
                      style={{ cursor: "no-drop" }}
                      placeholder="noreply@cuemail.in"
                      type="text"
                      name="Company"
                      disabled
                    />
                  </div>
                </div>
                <div className="contact">
                  <form onSubmit={handleSendEmails}>
                    <div>
                      <label>Select Groups:</label>
                      <Select
                        mode="multiple"
                        style={{ width: "100%" }}
                        placeholder="Select groups"
                        value={selectedOption}
                        onChange={handleSelectChange}
                      >
                        {groups.map((group) => (
                          <Option key={group.group_id} value={group.group_id}>
                            {group.group_name}
                          </Option>
                        ))}
                      </Select>
                    </div>

                    <p className="full">
                      <label>Enter Emails</label>
                      <div className="email-input-container">
                        <textarea
                          rows="5"
                          type="text"
                          id="email"
                          name="email"
                          className={
                            selectedOption.length > 0
                              ? "cursorNot"
                              : "cursorYes"
                          }
                          required
                          placeholder="Enter/Paste emails... (Each per line)"
                          value={receiverEmails}
                          onChange={(e) => setReceiverEmails(e.target.value)}
                        />

                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: "5px",
                          }}
                          className="overlay"
                        >
                          {receiverEmails.split("\n").map((email, index) => {
                            const emailParts = email.match(
                              /^(.+?)\s*<(.+?)>\s*,?$/
                            ) || [null, email, email];
                            const name = emailParts[1].trim();
                            const emailAddress = emailParts[2].trim();

                            return (
                              <span
                                key={index}
                                className={`email-address ${
                                  emailAddress.match(emailPattern)
                                    ? "valid-email"
                                    : "wrong-email"
                                }`}
                                contentEditable={true}
                                onBlur={(e) => {
                                  const editedEmails = [
                                    ...receiverEmails.split("\n"),
                                  ];
                                  editedEmails[index] = e.target.textContent;
                                  setReceiverEmails(editedEmails.join("\n"));
                                }}
                              >
                                {name
                                  ? `${name} <${emailAddress}>`
                                  : emailAddress}
                              </span>
                            );
                          })}
                        </div>
                      </div>
                      {duplicateEmails.length > 0 && (
                        <>
                          {duplicateEmails.map((duplicate, index) => (
                            <div
                              style={{ color: "red" }}
                              key={index}
                              className="duplicate-email-container"
                            >
                              <span>{duplicate} is a duplicate</span>
                            </div>
                          ))}
                          <button
                            style={{ backgroundColor: "#105252" }}
                            type="button" // Prevents the button from submitting the form
                            onClick={removeAllDuplicates}
                          >
                            Remove All Duplicates
                          </button>
                        </>
                      )}
                    </p>

                    <p className="full">
                      <label>Subject</label>
                      <Input
                        type="text"
                        id="subject"
                        name="subject"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                        placeholder="Subject"
                        required
                      />
                    </p>
                    <p className="full">
                      <label>Message</label>
                      <ReactQuill
                      className="react-quill"
                        value={body}
                        // onChange={setBody}
                        onChange={(value) => setBody(value)}
                        type="html/text"
                        modules={{
                          toolbar: [
                            [{ header: [1, 2, false] }],
                            [
                              "bold",
                              "italic",
                              "underline",
                              "strike",
                              "blockquote",
                              {
                                color: [
                                  "red",
                                  "green",
                                  "#FFF2D7",
                                  "#32012F",
                                  "#640D6B",
                                  "#322C2B",
                                  "#803D3B",
                                  "#FFEC9E",
                                  "#153448",
                                  "#121481",
                                  "#1A4D2E",
                                  "#C40C0C",
                                ],
                              },
                            ],
                            [{ list: "ordered" }, { list: "bullet" }],
                            ["link", "image"],
                            // [{ 'align': [] }],
                            ["clean"],
                          ],
                          paragraph: false,
                        }}
                        formats={[
                          "header",
                          "bold",
                          "italic",
                          "underline",
                          "strike",
                          "blockquote",
                          "list",
                          "bullet",
                          "link",
                          "image",
                          "color",
                    // "align",
                          "paragraph",
                        ]}
                        style={{
                          minHeight: "200px",
                          marginBottom: "30px",
                        }}
                      />
                    </p>
                    <p className="full">
                      <label>Signature</label>
                      <ReactQuill
                        value={signature}
                        onChange={setSignature}
                        modules={{
                          toolbar: [
                            [{ header: [1, 2, false] }],
                            [
                              "bold",
                              "italic",
                              "underline",
                              "strike",
                              "blockquote",
                              {
                                color: [
                                  "red",
                                  "green",
                                  "#FFF2D7",
                                  "#32012F",
                                  "#640D6B",
                                  "#322C2B",
                                  "#803D3B",
                                  "#FFEC9E",
                                  "#153448",
                                  "#121481",
                                  "#1A4D2E",
                                  "#C40C0C",
                                ],
                              },
                            ],
                            [{ list: "ordered" }, { list: "bullet" }],
                            ["link", "image"],
                            ["clean"],
                          ],
                          paragraph: false,
                        }}
                        formats={[
                          "header",
                          "bold",
                          "italic",
                          "underline",
                          "strike",
                          "blockquote",
                          "list",
                          "bullet",
                          "link",
                          
                          "color",
                          "paragraph",
                        ]}
                        style={{
                          minHeight: "200px",
                          marginBottom: "30px",
                        }}
                      />
                    </p>

                    <p className="full">
                      <Button
                        htmlType="submit"
                        className="submit"
                        style={{
                          backgroundColor: "#1F2937",
                          color: "white",
                          borderRadius: "5px",
                          width:"200px",
                          height:"50px"
                        }}
                        loading={loading}
                      >
                        Send Emails
                      </Button>
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default SendMailAno;
