import axios from "axios";
import { useState } from "react";
import swal from "sweetalert";

export const AddUserManual = async (
  userRole,
  user_name,
  unique_name,
  userEmail,
  phone,
  genderOptions,
  userStatus,
  userPassword


  
) => {
  const token = JSON.parse(localStorage.getItem("slotbook"));

  const accessToken = token?.accessToken;
  try {
    let userData = {
      user_role: parseInt(userRole),
      user_name: user_name,
      username: unique_name,
      user_email: userEmail,
      user_mobile: phone,
      user_gender: genderOptions,
      status: userStatus,
      password: userPassword,
    };

    let config = {
      method: "post",
      // url: "https://apipostman.onlinecu.in/exam_sch/api/user_table/create",
      url: "https://apipostman.onlinecu.in/exam_sch/api/user_table/create",

      data: userData,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const apiResponse = await axios(config);

    if (apiResponse?.status === 201) {
      swal({
        title: "User Created Success",
        text: ``,
        icon: "success",
        button: "Close",
      });

      // window.location.href = "/";
    } else {
    }
  } catch (error) {
    swal({
      title: "Something Wrong!!",
      text: error?.message,
      icon: "warning",
      button: "Close",
    });

    if (error.response?.status === 404) {
      swal({
        title: `${error.response?.data?.message}`,
        text: "Something went wrong.",
        icon: "warning",
        button: "Close",
      });
    } else if (error.response?.status === 401) {
      swal({
        title: `${error.response?.data?.message}`,
        text: "Something went wrong.",
        icon: "warning",
        button: "Close",
      });
    } else if (error.response?.status === 400) {
      swal({
        title: `${error.response?.statusText}`,
        text: "Oops Bad Request ",
        icon: "warning",
        button: "Close",
      });
    } else {
      swal({
        title: `${error.response?.data?.message}`,
        text: "Something went wrong.",
        icon: "warning",
        button: "Close",
      });
    }
  }
};
