import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";

const EnrolledUserProgressBar = () => {
  const [userData, setUserData] = useState([]);


  const token = JSON.parse(localStorage.getItem("slotbook"));

  const accessToken = token?.accessToken;

  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          // "https://apipostman.onlinecu.in/exam_sch/api/user_table/create"
          "https://apipostman.onlinecu.in/exam_sch/api/user_table/create", {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        const data = await response.json();
        setUserData(data.results);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // Count the number of users per department
  const departmentCounts = userData.reduce((acc, user) => {
    const department = user.department_name;
    acc[department] = (acc[department] || 0) + 1;
    return acc;
  }, {});

  // Convert the data to the format required by Chart.js
  const chartData = {
    labels: Object.keys(departmentCounts),
    datasets: [
      {
        label: "Enrolled Users",
        backgroundColor: "#E55353",
        borderColor: "grey",
        borderWidth: 2,
        data: Object.values(departmentCounts),
      },
    ],
  };

  const chartOptions = {
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        title: {
          display: true,
          text: "Department",
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  return (
    <div className="progress-bar-container">
      <h2 style={{ fontWeight: "bold", fontSize: "20px" }}>
      Total Users in Slot Booking.
      </h2>
      <Bar data={chartData} options={chartOptions} />
    </div>
  );
};

export default EnrolledUserProgressBar;
