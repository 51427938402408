import axios from "axios";
import swal from "sweetalert";

export const PostEnrollStudent = async (
  studentName,
  studentUid,
  studentEmail,
  phoneNumber,
  sessionSelected,
  selectedProgramLevel,
  genderSelected,
  selectedCourses,
  selectedSemester,
  selectedSpecializations,
  selectedElectives
) => {
  const token = JSON.parse(localStorage.getItem("slotbook"));
 
  const accessToken = token?.accessToken;
  try {
    let userData = {
      student_name: studentName,
      student_uid: studentUid,
      student_email: studentEmail,
      student_mobile: phoneNumber,
      session: sessionSelected,
      program_level: parseInt(selectedProgramLevel),
      gender: parseInt(genderSelected),
      program: parseInt(selectedCourses),
      semester: parseInt(selectedSemester),
    };


    // this is a function which conditionally send data of spec or elec if available 
    
    if (!!selectedSpecializations.length) {
      userData.spec = Array.isArray(selectedSpecializations)
        ? selectedSpecializations
        : [selectedSpecializations];
    }

    if (!!selectedElectives.length) {
      userData.elec = Array.isArray(selectedElectives)
        ? selectedElectives
        : [selectedElectives];
    }
    let config = {
      method: "post",
      // url: "https://apipostman.onlinecu.in/exam_sch/api/studentenrollment_list/",
      url: "https://apipostman.onlinecu.in/exam_sch/api/studentenrollment_list/",

      data: userData,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
   
    const apiResponse = await axios(config);
 

    if (apiResponse?.status === 201) {
     
      swal({
        title: "Enroll Success!",
        text: ``,
        icon: "success",
        button: "Close",
      });
    } else {
    }
  } catch (error) {
  
    if (error?.status === 400) {
      swal({
        title: `${error?.response?.data?.non_field_errors}`,
        text: `Something went wrong!`,
        icon: "success",
        button: "Close",
      });
    } else if (error?.status === 500) {
      swal({
        title: `${error.message}`,
        text: "Something went wrong.",
        icon: "warning",
        button: "Close",
      });
    } else {
      swal({
        title: `${error.message}`,
        text: "Something went wrong.",
        icon: "warning",
        button: "Close",
      });
    }
  }
};
