// ExcelDownloadButton.js
import React from "react";
import * as XLSX from "xlsx";

const ExcelDownloadButton = () => {
  const downloadExcel = () => {
    // Sample data
    const data = [
      [
        "user_name",
        "user_email",
        "user_password",
        "user_gender",
        "department",
        "user_role",
        "user_mobile",
      ],
      [
        "Raghu",
        "Raghu@gmail.com",
        "Saurabh@123",
        "Female",
        "online",
        "Admin",
        "+917891089215",
      ],
      [
        "Ram",
        "Ram@gmail.com",
        "Parth@123",
        "Male",
        "online",
        "Student",
        "+912785678896",
      ],
    ];

    const ws = XLSX.utils.aoa_to_sheet(data);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "sample.xlsx";
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  return (
    <button
      style={{ background: "white", color: "black", fontWeight: "bold" }}
      onClick={downloadExcel}
    >
      Download Excel Sample
    </button>
  );
};

export default ExcelDownloadButton;
