import React from "react";
import { Alert, Spin } from "antd";
const MenuSpinner = () => (
  <div className="example2">
    <Spin size="small" tip="Adding...">
     
    </Spin>
  </div>
);
export default MenuSpinner;
